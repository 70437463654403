import * as yup from 'yup';

import { InvoiceCustomFieldFields } from '../../../../../invoiceCustomFieldsTypes';

import { formsErrors } from '../../../../../../../locales/keys';
import { CreateInvoiceCustomFieldFormTabs } from '../../CreateInvoiceCustomFieldForm.types';
import { CustomFieldFields } from '../../../../../../customFields/customFieldsTypes';

export const createInvoiceCustomFieldFormSchema = (
  activeTab: CreateInvoiceCustomFieldFormTabs,
  withoutLabel: boolean
) =>
  yup.object({
    ...(activeTab === CreateInvoiceCustomFieldFormTabs.EXISTING_FIELD
      ? {
          [CustomFieldFields.CUSTOM_FIELD]: yup
            .object()
            .required(formsErrors.required)
        }
      : {
          [InvoiceCustomFieldFields.LABEL]: withoutLabel
            ? undefined
            : yup.string().required(formsErrors.required),
          [InvoiceCustomFieldFields.LOCATION]: yup
            .string()
            .required(formsErrors.required)
        })
  });
