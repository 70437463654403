import { Fragment, useMemo } from 'react';
import cl from 'classnames';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import format from 'date-fns/format';

import { ProformaInvoiceFormGroupByMode } from '../../../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';

import { useInvoiceItemsViewContext } from '../../hooks/useInvoiceItemsViewContext';

import { InvoiceItemsViewTableGroupRowsHeader } from '../InvoiceItemsViewTableGroupRowsHeader';
import { InvoiceItemsViewTableRow } from '../InvoiceItemsViewTableRow';

function InvoiceItemsViewTableBody() {
  const { items, watchFields } = useInvoiceItemsViewContext();

  const groupedItems = useMemo(
    () =>
      groupBy(items, (item) => {
        if (!watchFields.watchEnableGrouping) {
          return '';
        }

        switch (watchFields.watchGroupBy) {
          case ProformaInvoiceFormGroupByMode.PROJECT_TASK:
          case ProformaInvoiceFormGroupByMode.TASK:
            return item.taskNanoId;

          case ProformaInvoiceFormGroupByMode.PROJECT:
            return item.projectNanoId;

          case ProformaInvoiceFormGroupByMode.MONTH_INVOICE_CREATED:
            return item.invoiceCreationMonth;

          case ProformaInvoiceFormGroupByMode.MONTH_TASK_CREATED:
            return format(new Date(item.taskCreatedAt), 'yyyy-MM');

          case ProformaInvoiceFormGroupByMode.MONTH_TASK_DUE_DATE:
            return item.taskDueDateMonth;

          case ProformaInvoiceFormGroupByMode.MONTH_TASK_DONE:
            return item.taskDoneMonth;

          case ProformaInvoiceFormGroupByMode.MONTH_PROJECT_CREATED:
            return format(new Date(item.projectCreatedAt), 'yyyy-MM');

          case ProformaInvoiceFormGroupByMode.MONTH_PROJECT_CLOSED:
            return item.projectClosureMonth;

          // case ProformaInvoiceFormGroupByMode.INVOICE_TAG:
          //   break;

          default:
            return '';
        }
      }),
    [items, watchFields.watchEnableGrouping, watchFields.watchGroupBy]
  );

  return (
    <tbody>
      {map(groupedItems, (items, key) => (
        <Fragment key={key}>
          {key ? (
            <InvoiceItemsViewTableGroupRowsHeader items={items} />
          ) : (
            <tr className="h-2 !border-t-0"></tr>
          )}

          {map(items, (item, index) => (
            <InvoiceItemsViewTableRow
              className={cl('group')}
              item={item}
              index={index}
              key={index}
            />
          ))}

          <tr className="h-2 !border-t-0"></tr>
        </Fragment>
      ))}
    </tbody>
  );
}

export default InvoiceItemsViewTableBody;
