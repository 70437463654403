import { ProformaInvoiceFormViewMode } from '../../../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';
import { InvoiceStatuses } from '../../../../invoicesTypes';

import { useInvoiceItemsViewContext } from '../useInvoiceItemsViewContext';

function useInvoiceItemsViewColumns() {
  const { invoice, watchFields } = useInvoiceItemsViewContext();

  const showItemsCol =
    watchFields.watchViewMode === ProformaInvoiceFormViewMode.INVOICES;

  const showTasksCol =
    watchFields.watchViewMode === ProformaInvoiceFormViewMode.TASKS;

  const showProjectsCol =
    watchFields.watchViewMode === ProformaInvoiceFormViewMode.PROJECTS;

  const showCategoriesCol =
    watchFields.watchViewMode === ProformaInvoiceFormViewMode.CATEGORIES;

  const showOwnerCol = showProjectsCol || showTasksCol;

  const showQtyCol = showItemsCol || showCategoriesCol;

  const showCheckboxCol = invoice?.status === InvoiceStatuses.DRAFT;

  return {
    showItemsCol,
    showTasksCol,
    showProjectsCol,
    showCategoriesCol,
    showOwnerCol,
    showQtyCol,
    showCheckboxCol
  };
}

export default useInvoiceItemsViewColumns;
