import {
  FetchGroupedItemsFiltersType,
  FetchGroupedItemsCacheKeyType,
  FetchGroupedItemsLimitType,
  FetchGroupedItemsSortType,
  FetchGroupedItemsGqlQuery,
  GroupedItemsGroupBy
} from '../../groupedItemsTypes';
import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import { useFinInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useFinInfiniteIndexQuery';

import {
  INITIAL_GROUPED_ITEMS_FILTERS,
  INITIAL_GROUPED_ITEMS_LIMIT,
  INITIAL_GROUPED_ITEMS_SORT
} from '../../groupedItemsConstants';

type GroupedItemAdditionalParams = {
  convertTo?: string;
  currencyRateId?: string;
  groupBy?: GroupedItemsGroupBy[];
  groupItemsByType?: boolean;
  showTotal?: boolean;
  sort?: FetchGroupedItemsSortType;
};

interface GroupedItemsOptions {
  cacheKey: FetchGroupedItemsCacheKeyType;
  initialFilters?: FetchGroupedItemsFiltersType;
  initialSort?: FetchGroupedItemsSortType;
  initialLimit?: FetchGroupedItemsLimitType;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
  query: FetchGroupedItemsGqlQuery;
  additionalParams?: GroupedItemAdditionalParams;
}

const scope = 'groupedItems';

function useFinGroupedItems<
  FetchGroupedItemsItemType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  initialFilters = INITIAL_GROUPED_ITEMS_FILTERS,
  initialSort = INITIAL_GROUPED_ITEMS_SORT,
  initialLimit = INITIAL_GROUPED_ITEMS_LIMIT,
  options,
  query,
  additionalParams = {
    groupBy: [GroupedItemsGroupBy.ITEM],
    groupItemsByType: false,
    showTotal: true
  }
}: GroupedItemsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    sortItems,
    limitItems,
    loadMoreItems
  } = useFinInfiniteIndexQuery<FetchGroupedItemsItemType>({
    cacheKey,
    scope,
    initialFilters,
    initialLimit,
    initialSort,
    query,
    options,
    additionalParams
  });

  return {
    groupedItemsData: data,
    groupedItems: items,
    groupedItemsError: itemsError,
    groupedItemsTotalCount: itemsTotalCount,
    groupedItemsFetched: isFetched,
    groupedItemsLoading: isLoading,
    groupedItemsIsFetchingNextPage: isFetchingNextPage,
    groupedItemsIsPlaceholderData: isPlaceholderData,
    groupedItemsFilters: currentFilters,
    groupedItemsSort: currentSort,
    groupedItemsPage: currentPage,
    groupedItemsLimit: currentLimit,
    groupedItemsHasNextPage: hasNextPage,
    updateGroupedItemCache: updateItemCache,
    filterGroupedItems: filterItems,
    changeGroupedItemsFilters: changeItemsFilters,
    clearGroupedItemsFilters: clearItemsFilters,
    sortGroupedItems: sortItems,
    limitGroupedItems: limitItems,
    loadMoreGroupedItems: loadMoreItems
  };
}

export default useFinGroupedItems;
