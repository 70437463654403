import cl from 'classnames';

import { ClassName } from '../../../../../../types';

import {
  InvoiceItemsViewItemType,
  useInvoiceItemsViewContext
} from '../../hooks/useInvoiceItemsViewContext';
import { useInvoiceItemsViewColumns } from '../../hooks/useInvoiceItemsViewColumns';
import { useProformaInvoiceFormItemsViewDataTable } from '../../../../../teams/components/forms/ProformaInvoiceForm/components/ProformaInvoiceFormItemsView/hooks/useProformaInvoiceFormItemsViewDataTable';

import { IndexTableThreeStatesCheckbox } from '../../../../../common/components/tables/IndexTableThreeStatesCheckbox';
import { IndexTableCellDateTimeTooltip } from '../../../../../common/components/tables/IndexTableCellDateTimeTooltip';

import { NextPureLinkHelper } from '../../../../../../helpers/links/NextPureLinkHelper';
import { MoneyHelper } from '../../../../../../helpers/MoneyHelper';

import { TaskPath } from '../../../../../tasks/TaskPath';
import { ProjectPath } from '../../../../../projects/ProjectPath';
import { UserLink } from '../../../../../common/helpers/UserLink';

interface InvoiceItemsViewTableRowProps {
  item: InvoiceItemsViewItemType;
  className?: ClassName;
  index?: number;
}

const dateFormat = 'dd MMM yyyy';

function InvoiceItemsViewTableRow({
  item,
  className,
  index
}: InvoiceItemsViewTableRowProps) {
  const { checkedHash, handleSetCheckedIds, selectedCurrency } =
    useInvoiceItemsViewContext();

  const { checkboxState, handleCheckItems } =
    useProformaInvoiceFormItemsViewDataTable({
      items: item,
      checkedHash,
      onCheck: handleSetCheckedIds
    });

  const {
    showItemsCol,
    showTasksCol,
    showProjectsCol,
    showCategoriesCol,
    showCheckboxCol,
    showOwnerCol,
    showQtyCol
  } = useInvoiceItemsViewColumns();

  const paddingYStyles = index === 0 ? 'pt-2 pb-0.5' : 'py-0.5';

  return (
    <tr className={className || 'group align-top'}>
      {/* Checkbox */}
      {showCheckboxCol && (
        <td className={cl('w-0 text-xs break-wrap pl-1 pr-2', paddingYStyles)}>
          <IndexTableThreeStatesCheckbox
            checked={checkboxState}
            className="flex items-center justify-center"
            checkBoxClassName="basic-checkbox"
            itemId={item.groupedId}
            indeterminate={checkboxState === undefined}
            onCheck={handleCheckItems}
            scope="proforma-invoice-items"
          />
        </td>
      )}

      {/* Name */}
      <td
        className={cl(
          'text-xs break-wrap pl-1 pr-2 w-full text-left font-medium',
          paddingYStyles
        )}
        colSpan={showCategoriesCol ? 2 : 1}
      >
        {showItemsCol && (
          <>
            <span>{item.itemName}</span>
            <span className="text-gray-500 dark:text-gray-400 ml-1.5 italic">
              {item.splitPartPercent && item.splitPartPercent < 100
                ? `(${item.splitPartPercent}%)`
                : ''}
            </span>
          </>
        )}

        {showTasksCol && (
          <NextPureLinkHelper
            className="hover:underline"
            href={TaskPath.show(item.taskNanoId)}
            text={item.taskName}
          />
        )}

        {showProjectsCol && (
          <NextPureLinkHelper
            className="hover:underline"
            href={ProjectPath.messages(item.projectNanoId)}
            text={item.projectName}
          />
        )}

        {showCategoriesCol && <span>{item.itemCategoryName}</span>}
      </td>

      {showOwnerCol && (
        <td
          className={cl(
            'text-xs whitespace-nowrap pl-2 pr-2 w-0 text-left',
            paddingYStyles
          )}
        >
          {showTasksCol && (
            <UserLink
              className="hover:underline"
              user={item.taskOwner}
              text={item.taskOwner?.fullName}
            />
          )}
          {showProjectsCol && (
            <UserLink
              className="hover:underline"
              user={item.projectOwner}
              text={item.projectOwner?.fullName}
            />
          )}
        </td>
      )}

      {/* Task create date */}
      {showTasksCol && (
        <IndexTableCellDateTimeTooltip
          date={item.taskCreatedAt}
          customFormat={dateFormat}
          tdClassName={cl(
            'text-xs whitespace-nowrap pl-2 pr-2 w-0 text-left',
            paddingYStyles
          )}
        />
      )}

      {/* Project create date */}
      {showProjectsCol && (
        <IndexTableCellDateTimeTooltip
          date={item.projectCreatedAt}
          customFormat={dateFormat}
          tdClassName={cl(
            'text-xs whitespace-nowrap pl-2 pr-2 w-0 text-left',
            paddingYStyles
          )}
        />
      )}

      {/* Price */}
      {showItemsCol && (
        <td
          className={cl(
            'text-xs whitespace-nowrap pl-2 pr-2 w-0 text-left',
            paddingYStyles
          )}
        >
          <MoneyHelper
            currency={selectedCurrency}
            value={item.convertedPricePerItem}
            withPrefixIfUsd
            withSuffix
          />
        </td>
      )}

      {/* QTY */}
      {showQtyCol && (
        <td
          className={cl(
            'text-xs whitespace-nowrap pl-2 pr-2 w-0 text-right',
            paddingYStyles
          )}
        >
          {item.quantity}
        </td>
      )}

      {/* Subtotal */}
      <td
        className={cl(
          'text-xs whitespace-nowrap pl-2 pr-1 w-0 text-right',
          paddingYStyles
        )}
      >
        <MoneyHelper
          currency={selectedCurrency}
          value={item.convertedPrice}
          withPrefixIfUsd
          withSuffix
        />
      </td>
    </tr>
  );
}

export default InvoiceItemsViewTableRow;
