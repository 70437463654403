import { useCallback, useEffect, useState } from 'react';
import isArray from 'lodash/isArray';
import debounce from 'lodash/debounce';

import { Currencies } from '../../../../../../types';
import {
  FetchInvoicesCacheKeys,
  InvoiceCurrencyRateID,
  InvoiceID,
  InvoiceViewSettings
} from '../../../../invoicesTypes';
import {
  ProformaInvoiceFormGroupByMode,
  ProformaInvoiceFormViewMode
} from '../../../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';

import { INVOICE_UPDATE_VIEW_SETTINGS_QUERY } from '../../../../queries/invoiceUpdateViewSettings.query';

import { usePreviousValue } from '../../../../../../common/hooks/usePreviousValue';
import { useChangeCurrencyInvoice } from '../../../../hooks/useChangeCurrencyInvoice';
import { useUpdateViewSettingsInvoice } from '../../../../hooks/useUpdateViewSettingsInvoice';

import { MultiSelectFieldChangeCallbackType } from '../../../../../../helpers/FormFields/MultiSelectField';

interface InvoiceItemsViewHandlersOptions {
  invoice?: {
    id: InvoiceID;
    currencyRateId: InvoiceCurrencyRateID;
  };
  cacheKeys: FetchInvoicesCacheKeys;
  viewMode: ProformaInvoiceFormViewMode;
  groupBy: ProformaInvoiceFormGroupByMode;
  enableGrouping: boolean;
}

const isNewValue = (prevValue, newValue) =>
  prevValue !== undefined && prevValue !== newValue;

function useInvoiceItemsViewHandlers({
  invoice,
  cacheKeys,
  viewMode,
  groupBy,
  enableGrouping
}: InvoiceItemsViewHandlersOptions) {
  const prevViewMode = usePreviousValue(viewMode);
  const prevGroupBy = usePreviousValue(groupBy);
  const prevEnableGrouping = usePreviousValue(enableGrouping);

  const {
    updateViewSettingsInvoice,
    updateViewSettingsInvoiceLoading,
    updateViewSettingsInvoiceError
  } = useUpdateViewSettingsInvoice({
    query: INVOICE_UPDATE_VIEW_SETTINGS_QUERY,
    cacheKeys: cacheKeys
  });

  // const updateViewSettings = useRef()

  // const updateViewSettings = useCallback(
  //   debounce(updateViewSettingsInvoice, 200),
  //   [updateViewSettingsInvoice]
  // );

  useEffect(() => {
    if (
      !isNewValue(prevViewMode, viewMode) &&
      !isNewValue(prevGroupBy, groupBy) &&
      !isNewValue(prevEnableGrouping, enableGrouping)
    ) {
      return;
    }

    const newInvoiceViewSettings: Partial<InvoiceViewSettings> = {
      viewMode,
      groupBy,
      enableGrouping
    };

    // setInvoiceViewSettings(newInvoiceViewSettings);

    void updateViewSettingsInvoice({
      uuid: invoice?.id,
      viewSettings: newInvoiceViewSettings
    });
  }, [
    prevEnableGrouping,
    prevGroupBy,
    prevViewMode,
    viewMode,
    groupBy,
    enableGrouping,
    invoice?.id,
    updateViewSettingsInvoice
  ]);

  const { changeCurrency, changeCurrencyLoading, changeCurrencyErrorMessage } =
    useChangeCurrencyInvoice({
      cacheKeys
    });

  const [changeCurrencyDebouncedLoading, setChangeCurrencyDebouncedLoading] =
    useState(changeCurrencyLoading);

  useEffect(() => {
    if (!changeCurrencyLoading) {
      setTimeout(() => setChangeCurrencyDebouncedLoading(false), 300);
      return;
    }

    setChangeCurrencyDebouncedLoading(changeCurrencyLoading);
  }, [changeCurrencyLoading]);

  const handleChangeCurrency = useCallback<MultiSelectFieldChangeCallbackType>(
    (data) =>
      changeCurrency({
        invoiceId: invoice?.id,
        invoice: {
          currencyRateId: invoice?.currencyRateId,
          selectedCurrency: (isArray(data) ? data[0] : data)
            ?.value as Currencies
        }
      }),
    [changeCurrency, invoice?.currencyRateId, invoice?.id]
  );

  return {
    handleChangeCurrency,
    updateViewSettingsInvoiceLoading,
    updateViewSettingsInvoiceError,
    changeCurrencyLoading: changeCurrencyDebouncedLoading,
    changeCurrencyError: changeCurrencyErrorMessage
  };
}

export default useInvoiceItemsViewHandlers;
