import { useCallback, useEffect, useMemo } from 'react';

import { FetchGroupedItemsCacheKeyType } from '../../../../../groupedItems/groupedItemsTypes';
import {
  InvoiceCurrencyRateID,
  InvoiceNanoID
} from '../../../../invoicesTypes';
import { Currencies, UUID } from '../../../../../../types';
import {
  ProformaInvoiceFormGroupByMode,
  ProformaInvoiceFormViewMode
} from '../../../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';
import {
  FETCH_GROUPED_INVOICE_ITEMS_QUERY,
  FetchedGroupedInvoiceItemsQueryResponse
} from '../../../../../groupedItems/queries/fetchGroupedInvoiceItems.query';

import { usePreviousValue } from '../../../../../../common/hooks/usePreviousValue';
import { useFinGroupedItems } from '../../../../../groupedItems/hooks/useFinGroupedItems';

import { GroupedItemCache } from '../../../../../groupedItems/GroupedItemCache';
import { getGroupByFromViewMode } from '../../../../../teams/components/forms/ProformaInvoiceForm/utils/getGroupByFromViewMode';
import { getDefaultGroupedItemsSort } from '../../../../../teams/components/forms/ProformaInvoiceForm/utils/getDefaultGroupedItemsSort';

const groupedItemsInitialLimit = 1000;

interface InvoiceItemsViewItemsOptions {
  invoiceNanoId: InvoiceNanoID;
  currencyRateId: InvoiceCurrencyRateID;
  currency: Currencies;
  viewMode: ProformaInvoiceFormViewMode;
  groupBy: ProformaInvoiceFormGroupByMode;
  enableGrouping: boolean;
}

function useInvoiceItemsViewItems({
  invoiceNanoId,
  currencyRateId,
  currency,
  viewMode,
  groupBy,
  enableGrouping
}: InvoiceItemsViewItemsOptions) {
  const cacheKey = GroupedItemCache.invoiceGroupedItemsCacheKey(invoiceNanoId);

  const cacheKeys = useMemo<FetchGroupedItemsCacheKeyType[]>(
    () => [cacheKey],
    [cacheKey]
  );

  const additionalParams = useMemo(() => {
    const groupByParam = getGroupByFromViewMode(
      viewMode,
      groupBy,
      enableGrouping
    );

    return {
      convertTo: currency.toLocaleLowerCase(),
      currencyRateId,
      groupBy: groupByParam,
      groupItemsByType: viewMode === ProformaInvoiceFormViewMode.INVOICES,
      showTotal: true,
      sort: getDefaultGroupedItemsSort(groupByParam)
    };
  }, [currency, currencyRateId, enableGrouping, groupBy, viewMode]);

  const initialFilters = useMemo(
    () => ({
      parentOrTempInvoiceNanoId: {
        eq: invoiceNanoId
      }
    }),
    [invoiceNanoId]
  );

  const prevFilters = usePreviousValue(initialFilters);

  const {
    groupedItems,
    groupedItemsFetched,
    groupedItemsFilters,
    groupedItemsSort,
    groupedItemsLoading,
    groupedItemsError,
    filterGroupedItems,
    sortGroupedItems,
    groupedItemsHasNextPage,
    loadMoreGroupedItems,
    groupedItemsIsFetchingNextPage
  } = useFinGroupedItems<
    FetchedGroupedInvoiceItemsQueryResponse & {
      uuid: UUID;
    }
  >({
    query: FETCH_GROUPED_INVOICE_ITEMS_QUERY,
    cacheKey,
    initialFilters,
    initialLimit: groupedItemsInitialLimit,
    initialSort: getDefaultGroupedItemsSort(additionalParams.groupBy),
    additionalParams,
    options: {
      enabled: !!invoiceNanoId
    }
  });

  useEffect(() => {
    if (prevFilters !== initialFilters) {
      filterGroupedItems(initialFilters);
    }
  }, [prevFilters, filterGroupedItems, initialFilters]);

  useEffect(() => {
    if (groupedItemsHasNextPage && !groupedItemsIsFetchingNextPage) {
      void loadMoreGroupedItems();
    }
  }, [
    groupedItemsHasNextPage,
    groupedItemsIsFetchingNextPage,
    loadMoreGroupedItems
  ]);

  const handleClearGroupedItemsFilters = useCallback(() => {
    filterGroupedItems(initialFilters);
  }, [filterGroupedItems, initialFilters]);

  return {
    initialFilters,
    cacheKeys,
    groupedItemsLoading,
    groupedItemsFetched,
    groupedItemsError,
    groupedItems,
    groupedItemsSort,
    sortGroupedItems,
    groupedItemsFilters,
    filterGroupedItems,
    handleClearGroupedItemsFilters
  };
}

export default useInvoiceItemsViewItems;
