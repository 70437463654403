import {
  FetchCustomFieldValuesCacheKey,
  FetchCustomFieldValuesFilters,
  FetchCustomFieldValuesLimit,
  FetchCustomFieldValuesPage,
  FetchCustomFieldValuesSort,
  FetchCustomFieldValuesGqlQuery
} from '../../customFieldValuesTypes';
import { IndexQueryDefaultOptionsOpts } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

import {
  INITIAL_CUSTOM_FIELD_FILTERS,
  INITIAL_CUSTOM_FIELD_LIMIT,
  INITIAL_CUSTOM_FIELD_PAGE,
  INITIAL_CUSTOM_FIELD_SORT
} from '../../customFieldValuesConstants';

interface PaginatedCustomFieldsOptions<FetchCustomFieldsCustomFieldValueType> {
  cacheKey: FetchCustomFieldValuesCacheKey;
  initialFilters?: FetchCustomFieldValuesFilters;
  initialSort?: FetchCustomFieldValuesSort;
  initialPage?: FetchCustomFieldValuesPage;
  initialLimit?: FetchCustomFieldValuesLimit;
  options?: IndexQueryDefaultOptionsOpts<FetchCustomFieldsCustomFieldValueType>;
  query: FetchCustomFieldValuesGqlQuery;
}

const scope = 'customFieldValues';

function usePaginatedCustomFieldValues<FetchCustomFieldsCustomFieldValueType>({
  cacheKey,
  initialFilters = INITIAL_CUSTOM_FIELD_FILTERS,
  initialSort = INITIAL_CUSTOM_FIELD_SORT,
  initialPage = INITIAL_CUSTOM_FIELD_PAGE,
  initialLimit = INITIAL_CUSTOM_FIELD_LIMIT,
  options = {},
  query
}: PaginatedCustomFieldsOptions<FetchCustomFieldsCustomFieldValueType>) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<FetchCustomFieldsCustomFieldValueType>({
    cacheKey,
    scope,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query
  });

  return {
    customFieldValuesData: data,
    customFieldValues: items,
    customFieldValuesError: itemsError,
    customFieldValuesErrorMessage: itemsErrorMessage,
    customFieldValuesTotalCount: itemsTotalCount,
    customFieldValuesFetched: isFetched,
    customFieldValuesLoading: isLoading,
    customFieldValuesIsPlaceholderData: isPlaceholderData,
    customFieldValuesFilters: currentFilters,
    customFieldValuesSort: currentSort,
    customFieldValuesPage: currentPage,
    customFieldValuesLimit: currentLimit,
    updateCustomFieldValueCache: updateItemCache,
    filterCustomFieldValues: filterItems,
    changeCustomFieldValuesFilters: changeItemsFilters,
    clearCustomFieldValuesFilters: clearItemsFilters,
    sortCustomFieldValues: sortItems,
    paginateCustomFieldValues: paginateItems,
    limitCustomFieldValues: limitItems,
    prefetchCustomFieldValues: prefetchItems
  };
}

export default usePaginatedCustomFieldValues;
