import {
  InvoiceAmount,
  InvoiceAvInvoiceBillingInfoPaymentMethods,
  InvoiceClientStatus,
  InvoiceCurrencyRateID,
  InvoiceGeneralLedgerCompanyID,
  InvoiceGeneralLedgerCompanyNanoID,
  InvoiceID,
  InvoiceNanoID,
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceSelectedCurrency,
  InvoiceSelectedCurrencyRate,
  InvoiceStatus,
  InvoiceTempTotalConverted,
  InvoiceTotal,
  InvoiceTotalConverted,
  InvoiceViewSettings
} from '../../invoicesTypes';
import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOptions,
  InvoiceCustomFieldOrder
} from '../../../invoiceCustomFields/invoiceCustomFieldsTypes';
import { CompanyNanoID } from '../../../companies/companiesTypes';
import { MultiSelectGroupedDataType } from '../../../../helpers/MultiSelect/types';
import {
  ProformaInvoiceFormGroupByMode,
  ProformaInvoiceFormViewMode
} from '../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';
import { Currencies } from '../../../../types';

export interface InvoiceItemsViewInvoice {
  id: InvoiceID;
  nanoId: InvoiceNanoID;
  amount: InvoiceAmount;
  invoiceTotal: InvoiceTotal;
  invoiceTotalConverted: InvoiceTotalConverted;
  invoiceTempTotalConverted: InvoiceTempTotalConverted;
  prepayment: InvoicePrepayment;
  prepaymentAmount: InvoicePrepaymentAmount;
  clientStatus: InvoiceClientStatus;
  status: InvoiceStatus;
  currencyRateId: InvoiceCurrencyRateID;
  selectedCurrency: InvoiceSelectedCurrency;
  selectedCurrencyRate: InvoiceSelectedCurrencyRate;
  viewSettings: InvoiceViewSettings;
  avInvoiceBillingInfo: {
    paymentMethod: InvoiceAvInvoiceBillingInfoPaymentMethods;
  };
  invoiceCustomFields: {
    id: InvoiceCustomFieldID;
    customFieldId: InvoiceCustomFieldID;
    label: InvoiceCustomFieldLabel;
    body: InvoiceCustomFieldBody;
    location: InvoiceCustomFieldLocation;
    order: InvoiceCustomFieldOrder;
    options: InvoiceCustomFieldOptions;
  }[];
  secondaryInvoice?: {
    amount: InvoiceAmount;
    clientStatus: InvoiceClientStatus;
    status: InvoiceStatus;
  };
  generalLedger: {
    company: {
      id: InvoiceGeneralLedgerCompanyID;
      nanoId: InvoiceGeneralLedgerCompanyNanoID;
    };
  };
}

export interface InvoiceItemsViewData {
  currency?: Currencies;
  viewMode?: ProformaInvoiceFormViewMode;
  groupBy?: ProformaInvoiceFormGroupByMode;
  enableGrouping?: boolean;
}

export const enum InvoiceItemsViewFields {
  CURRENCY = 'currency',
  VIEW_MODE = 'viewMode',
  ENABLE_GROUPING = 'enableGrouping',
  GROUP_BY = 'groupBy'
}

export const defaultInvoiceViewSettings = {
  viewMode: ProformaInvoiceFormViewMode.INVOICES,
  groupBy: ProformaInvoiceFormGroupByMode.PROJECT,
  enableGrouping: true
};

export const invoiceViewSettingsLocalStoreKey = (
  companyNanoId: CompanyNanoID
) => `company-${companyNanoId}-payments-invoices-view-settings`;

export type InvoiceItemsViewGroupByOptions = Record<
  Exclude<ProformaInvoiceFormViewMode, ProformaInvoiceFormViewMode.CATEGORIES>,
  MultiSelectGroupedDataType[]
>;
