import { Control } from 'react-hook-form';

import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';
import { UpdateInvoiceCustomFieldFormData } from './UpdateInvoiceCustomFieldForm.types';
import { CustomFieldFields } from '../../../../customFields/customFieldsTypes';
import { InvoiceCustomFieldOptions } from '../../../invoiceCustomFieldsTypes';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { TextareaAutosizeField } from '../../../../../helpers/FormFields/TextareaAutosizeField';
import { CustomFieldLocationsSelectField } from '../../../../customFields/helpers/CustomFieldLocationsSelectField';
import { CheckBoxField } from '../../../../../helpers/FormFields/CheckBoxField';

import {
  customFieldsKeys,
  formsFields,
  stringsKeys,
  words
} from '../../../../../locales/keys';

interface UpdateInvoiceCustomFieldFormProps {
  form: string;
  isLoading: IsLoading;
  control: Control<UpdateInvoiceCustomFieldFormData>;
  registerBody: RegisterFormFieldType<HTMLInputElement>;
  registerLabel: RegisterFormFieldType<HTMLInputElement>;
  registerMultiline: RegisterFormFieldType<HTMLInputElement>;
  registerSaveInCompany: RegisterFormFieldType<HTMLInputElement>;
  labelValidationError: ErrorMessage;
  locationValidationError: ErrorMessage;
  withoutLabel?: boolean;
  withoutLocation?: boolean;
  watchOptions: InvoiceCustomFieldOptions;
  watchSaveInCompany: boolean;
}

function UpdateInvoiceCustomFieldForm({
  form,
  isLoading,
  control,
  registerBody,
  registerLabel,
  registerMultiline,
  registerSaveInCompany,
  labelValidationError,
  locationValidationError,
  withoutLabel,
  withoutLocation,
  watchOptions,
  watchSaveInCompany
}: UpdateInvoiceCustomFieldFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4 space-y-4">
        {!withoutLabel && (
          <InputField
            disabled={isLoading}
            error={labelValidationError}
            i18nLabel={formsFields.label}
            inputWrapperClassName="relative mt-1"
            labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
            name={registerLabel.name}
            onChange={registerLabel.onChange}
            ref={registerLabel.ref}
          />
        )}

        <div>
          <TextareaAutosizeField<UpdateInvoiceCustomFieldFormData>
            i18nLabel={formsFields.body}
            control={control}
            disabled={isLoading}
            className="basic-input resize-none"
            inputWrapperClassName="relative"
            labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            name={registerBody.name as keyof UpdateInvoiceCustomFieldFormData}
            maxRows={5}
          />
        </div>

        <CheckBoxField
          id={registerMultiline.name}
          i18nLabel={words.multiline}
          name={registerMultiline.name}
          onChange={registerMultiline.onChange}
          onBlur={registerMultiline.onBlur}
          ref={registerMultiline.ref}
          checked={!!watchOptions?.multiline}
        />

        {!withoutLocation && (
          <CustomFieldLocationsSelectField<UpdateInvoiceCustomFieldFormData>
            control={control}
            name={CustomFieldFields.LOCATION}
            i18nLabel={words.location}
            error={locationValidationError}
            i18nPlaceholder={customFieldsKeys.selectLocation}
          />
        )}

        <CheckBoxField
          id={registerSaveInCompany.name}
          i18nLabel={stringsKeys.saveFieldInCompanyProfile}
          name={registerSaveInCompany.name}
          onChange={registerSaveInCompany.onChange}
          onBlur={registerSaveInCompany.onBlur}
          ref={registerSaveInCompany.ref}
          checked={watchSaveInCompany}
        />
      </div>
    </Form>
  );
}

export default UpdateInvoiceCustomFieldForm;
