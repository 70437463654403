import {
  CreateItemCacheKeys,
  CreateItemGqlQuery,
  CreatedAt,
  DateFilterType,
  DeleteItemCacheKeys,
  DeleteItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  ID,
  IdFilterType,
  UUID,
  UpdateItemCacheKeys,
  UpdateItemGqlQuery,
  UpdatedAt,
  UuidFilterType
} from '../../types';
import { CompanyID } from '../companies/companiesTypes';

export type CustomFieldValueID = ID;
export type CustomFieldValueUUID = UUID;
export type CustomFieldValueText = string;
export type CustomFieldValueCompanyID = CompanyID;
export type CustomFieldValueCreatedAt = CreatedAt;
export type CustomFieldValueDefaultAt = Date;
export type CustomFieldValueUpdatedAt = UpdatedAt;

export const enum FetchCustomFieldValuesSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC'
}

export interface FetchCustomFieldValuesFilters {
  companyId?: IdFilterType;
  createdAt?: DateFilterType;
  customFieldId?: IdFilterType;
  id?: IdFilterType;
  updatedAt?: DateFilterType;
  uuid?: UuidFilterType;
}

export type FetchCustomFieldValuesGqlQuery = FetchItemsGqlQuery;

export type FetchCustomFieldValuesCacheKey = FetchItemsCacheKey;

export type FetchCustomFieldValuesSort = FetchItemsSort;
export type FetchCustomFieldValuesPage = FetchItemsPage;
export type FetchCustomFieldValuesLimit = FetchItemsLimit;

export type CreateCustomFieldValueGqlQuery = CreateItemGqlQuery;
export type CreateCustomFieldValueCacheKeys = CreateItemCacheKeys;
export type UpdateCustomFieldValueGqlQuery = UpdateItemGqlQuery;
export type UpdateCustomFieldValueCacheKeys = UpdateItemCacheKeys;
export type DeleteCustomFieldValueGqlQuery = DeleteItemGqlQuery;
export type DeleteCustomFieldValueCacheKeys = DeleteItemCacheKeys;
