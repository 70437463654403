import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import map from 'lodash/map';

import { UpdateInvoiceCustomFieldFormData } from '../../UpdateInvoiceCustomFieldForm.types';
import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldFields,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOptions,
  UpdateInvoiceCustomFieldCacheKeys
} from '../../../../../invoiceCustomFieldsTypes';
import { CustomFieldLocations } from '../../../../../../customFields/customFieldsTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { updateInvoiceCustomFieldFormSchema } from './useUpdateInvoiceCustomFieldForm.schema';
import { useUpdateInvoiceCustomField } from '../../../../../hooks/useUpdateInvoiceCustomField';
import { UPDATE_INVOICE_CUSTOM_FIELD_QUERY } from '../../../../../queries/updateInvoiceCustomField.query';
import { CompanyID } from '../../../../../../companies/companiesTypes';
import { useCreateCustomField } from '../../../../../../customFields/hooks/useCreateCustomField';
import { CREATE_CUSTOM_FIELD_QUERY } from '../../../../../../customFields/queries/createCustomField.query';
import { useCreateCustomFieldValue } from '../../../../../../customFieldValues/hooks/useCreateCustomFieldValue';
import { CREATE_CUSTOM_FIELD_VALUE_QUERY } from '../../../../../../customFieldValues/queries/createCustomFieldValue.query';

interface UpdateInvoiceCustomFieldFormProps {
  invoiceCustomFieldId: InvoiceCustomFieldID;
  cacheKeys?: UpdateInvoiceCustomFieldCacheKeys;
  label: InvoiceCustomFieldLabel;
  body?: InvoiceCustomFieldBody;
  location: InvoiceCustomFieldLocation;
  options: InvoiceCustomFieldOptions;
  withoutLabel?: boolean;
  companyId: CompanyID;
}

const defaultInvoiceCustomFieldValues: UpdateInvoiceCustomFieldFormData = {
  label: '',
  body: '',
  location: CustomFieldLocations.HEADER,
  options: { multiline: false },
  saveInCompany: false
};

function useUpdateInvoiceCustomFieldForm({
  invoiceCustomFieldId,
  cacheKeys = [],
  withoutLabel,
  label,
  body,
  location,
  options,
  companyId
}: UpdateInvoiceCustomFieldFormProps) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    setValue,
    watch
  } = useReactHookForm<UpdateInvoiceCustomFieldFormData>({
    defaultValues: {
      ...defaultInvoiceCustomFieldValues,
      label,
      body,
      location,
      options
    },
    isModalForm: true,
    schema: updateInvoiceCustomFieldFormSchema(withoutLabel)
  });

  const queryClient = useQueryClient();

  const {
    createCustomFieldReset,
    createCustomFieldLoading,
    createCustomFieldErrorMessage,
    createCustomField
  } = useCreateCustomField({
    // cacheKeys: cacheKeys,
    query: CREATE_CUSTOM_FIELD_QUERY
  });

  const {
    createCustomFieldValueReset,
    createCustomFieldValueLoading,
    createCustomFieldValueErrorMessage,
    createCustomFieldValue
  } = useCreateCustomFieldValue({
    // cacheKeys: cacheKeys,
    query: CREATE_CUSTOM_FIELD_VALUE_QUERY
  });

  const {
    updateInvoiceCustomFieldReset,
    updateInvoiceCustomFieldLoading,
    updateInvoiceCustomFieldErrorMessage,
    updateInvoiceCustomField
  } = useUpdateInvoiceCustomField({
    // cacheKeys: cacheKeys,
    query: UPDATE_INVOICE_CUSTOM_FIELD_QUERY
  });

  const handleInitUpdateInvoiceCustomFieldForm = useCallback(() => {
    setValue(InvoiceCustomFieldFields.LABEL, label);
    setValue(InvoiceCustomFieldFields.BODY, body);
    setValue(InvoiceCustomFieldFields.LOCATION, location);
    setValue(InvoiceCustomFieldFields.OPTIONS, options);
  }, [body, label, location, options, setValue]);

  return {
    validationErrors: {
      labelValidationError: errors?.label?.message,
      bodyValidationError: errors?.body?.message,
      locationValidationError: errors?.location?.message
    },
    control,
    updateInvoiceCustomFieldReset,
    updateInvoiceCustomFieldLoading,
    updateInvoiceCustomFieldErrorMessage,
    createCustomFieldReset,
    createCustomFieldLoading,
    createCustomFieldErrorMessage,
    createCustomFieldValueReset,
    createCustomFieldValueLoading,
    createCustomFieldValueErrorMessage,
    resetUpdateInvoiceCustomFieldForm: resetForm,
    handleInitUpdateInvoiceCustomFieldForm,
    handleSubmitReactHookForm,
    handleUpdateInvoiceCustomField: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) => {
        await updateInvoiceCustomField({
          uuid: invoiceCustomFieldId,
          body: data.body,
          label: data.label,
          options: data.options,
          location: data.location
        });

        if (data.saveInCompany) {
          const res = await createCustomField({
            companyId,
            location: data.location,
            options: data.options,
            body: data.body,
            // TODO: remove when backend turns off validation
            label: data.label,
            ...(withoutLabel
              ? {
                  label: data.location
                }
              : {})
          });

          const customFieldId = res.createCustomField?.recordId;

          if (customFieldId) {
            await createCustomFieldValue({
              companyId,
              customFieldId,
              text: data.body
            });
          }
        }

        map(cacheKeys, (eachCacheKey) =>
          queryClient.invalidateQueries(eachCacheKey)
        );
      }
    }),
    registerFields: {
      registerLabel: register(InvoiceCustomFieldFields.LABEL),
      registerBody: register(InvoiceCustomFieldFields.BODY),
      registerLocation: register(InvoiceCustomFieldFields.LOCATION),
      registerMultiline: register(InvoiceCustomFieldFields.OPTIONS_MULTILINE),
      registerSaveInCompany: register(InvoiceCustomFieldFields.SAVE_IN_COMPANY)
    },
    watchOptions: watch(InvoiceCustomFieldFields.OPTIONS),
    watchSaveInCompany: watch(InvoiceCustomFieldFields.SAVE_IN_COMPANY)
  };
}

export default useUpdateInvoiceCustomFieldForm;
