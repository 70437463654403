import { gql } from 'graphql-request';

export const CREATE_INVOICE_CUSTOM_FIELD_QUERY = gql`
  mutation CreateInvoiceCustomField(
    $body: String
    $invoiceId: ID!
    $label: String!
    $location: String!
    $options: JSON
    $order: Float
  ) {
    createInvoiceCustomField(
      input: {
        body: $body
        invoiceId: $invoiceId
        label: $label
        location: $location
        options: $options
        order: $order
      }
    ) {
      appVersion
      errors {
        fullMessages
      }
      record {
        appVersion
        body
        createdAt
        id
        invoiceId
        label
        location
        type
        updatedAt
        userId
        uuid
      }
      recordId
      recordNanoId
      recordUuid
      status
    }
  }
`;
