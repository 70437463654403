import { createContext, ReactNode, useContext } from 'react';

import { useInvoiceItemsView } from '../useInvoiceItemsView';

import { InvoiceItemsViewInvoice } from '../../InvoiceItemsView.types';
import { Currencies, ID } from '../../../../../../types';
import { InvoiceItemsViewContextType } from './useInvoiceItemsViewContext.types';
import {
  ProformaInvoiceFormGroupByMode,
  ProformaInvoiceFormViewMode
} from '../../../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';
import {
  FetchInvoicesCacheKeys,
  InvoiceNanoID
} from '../../../../invoicesTypes';

const InvoiceItemsViewContext = createContext<InvoiceItemsViewContextType>({
  invoice: undefined,
  invoiceNanoId: undefined,
  control: null,
  cacheKeys: [],
  checkedAll: false,
  checkedHash: null,
  checkedItems: null,
  checkedPartial: false,
  handleCheckAll(): void {
    console.log('handleCheckAll should be initialized');
  },
  handleUncheckAll(): void {
    console.log('handleUncheckAll should be initialized');
  },
  handleSetCheckedIds(id: ID | ID[]): void {
    console.log('handleUncheckAll should be initialized');
  },
  viewModeOptions: [],
  groupByOptions: null,
  watchFields: {
    watchViewMode: ProformaInvoiceFormViewMode.INVOICES,
    watchGroupBy: ProformaInvoiceFormGroupByMode.PROJECT
  },
  registerFields: {
    registerEnabledGrouping: null
  },
  currentUser: null,
  items: [],
  groupedItemsFetched: false,
  groupedItemsLoading: false,
  currencyPrefix: Currencies.USD,
  checkedItemsTotal: 0,
  thirdPersonView: false,
  self: false,
  invoiceStatus: undefined,
  payments: 0,
  prepayment: 0,
  prepaymentAmount: 0,
  amountDue: 0,
  invoiceTotal: 0,
  updateViewSettingsInvoiceLoading: false,
  updateViewSettingsInvoiceError: null,
  groupedItemsError: null,
  changeCurrencyLoading: false,
  changeCurrencyError: null,
  selectedCurrency: null
});

interface InvoiceItemsViewProviderProps {
  children: ReactNode;
  invoice: InvoiceItemsViewInvoice;
  invoiceNanoId: InvoiceNanoID;
  thirdPersonView?: boolean;
  self?: boolean;
  cacheKeys?: FetchInvoicesCacheKeys;
  customFieldsCorrectStatus?: boolean;
}

export function InvoiceItemsViewProvider({
  children,
  invoice,
  invoiceNanoId,
  thirdPersonView,
  self,
  cacheKeys = [],
  customFieldsCorrectStatus
}: InvoiceItemsViewProviderProps) {
  const invoiceItemsViewContext = useInvoiceItemsView({
    invoice,
    invoiceNanoId,
    thirdPersonView,
    self,
    cacheKeys,
    customFieldsCorrectStatus
  });

  return (
    <InvoiceItemsViewContext.Provider value={invoiceItemsViewContext}>
      {children}
    </InvoiceItemsViewContext.Provider>
  );
}

export function useInvoiceItemsViewContext() {
  return useContext(InvoiceItemsViewContext);
}
