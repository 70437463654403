import {
  InvoiceItemsViewData,
  InvoiceItemsViewFields
} from '../../InvoiceItemsView.types';

import { ProformaInvoiceFormFields } from '../../../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';
import { AvBillingInfoPaymentMethods } from '../../../../../avBillingInfos/avBillingInfosTypes';

import { useInvoiceItemsViewContext } from '../../hooks/useInvoiceItemsViewContext';

import { ProformaInvoiceFormSelectField } from '../../../../../teams/components/forms/ProformaInvoiceForm/components/fields/ProformaInvoiceFormSelectField';
import { ProformaInvoiceFormGroupBySelectField } from '../../../../../teams/components/forms/ProformaInvoiceForm/components/fields/ProformaInvoiceFormGroupBySelectField';

import { CurrencySelectField } from '../../../../../../helpers/FormFields/CurrencySelectField';
import { ExchangeRatesListTooltipIconButton } from '../../../../../currencyRates/helpers/ExchangeRatesListTooltipIconButton';
import { Translate } from '../../../../../../helpers/Translate';
import { CheckPermissions } from '../../../../../../helpers/CheckPermissions';
import { CheckBoxField } from '../../../../../../helpers/FormFields/CheckBoxField';

import { baseCurrenciesList } from '../../../../../currencyRates/currencyRatesConstants';
import { InvoicesPermissions } from '../../../../invoicesConstants';
import { words } from '../../../../../../locales/keys';
import { TeamsPermissions } from '../../../../../teams/teamsConstants';

function InvoiceItemsViewHeader() {
  const {
    control,
    watchFields,
    viewModeOptions,
    groupByOptions,
    registerFields,
    thirdPersonView,
    self,
    invoice,
    handleChangeCurrency
  } = useInvoiceItemsViewContext();

  return (
    <div className="break-inside-avoid flex gap-1 mb-4 sm:-mx-4 text-sm">
      <div className="flex-1 gap-6 flex items-center bg-gray-50 dark:bg-gray-850 rounded-md py-2 px-4">
        <CheckPermissions
          action={
            thirdPersonView
              ? self
                ? TeamsPermissions.READ_SELF_PAYMENTS_INVOICE_ITEMS_TABLE_GROUPING
                : TeamsPermissions.READ_OTHER_PAYMENTS_INVOICE_ITEMS_TABLE_GROUPING
              : InvoicesPermissions.READ_PAYMENTS_INVOICE_ITEMS_TABLE_GROUPING
          }
        >
          <div className="flex gap-2 av_select--custom-paddings">
            <span>
              <Translate id={words.view} />
            </span>
            <ProformaInvoiceFormSelectField
              control={control}
              data={viewModeOptions}
              name={InvoiceItemsViewFields.VIEW_MODE}
            />
          </div>

          <div className="flex gap-2 av_select--custom-paddings">
            <CheckBoxField
              id={registerFields.registerEnabledGrouping.name}
              name={registerFields.registerEnabledGrouping.name}
              ref={registerFields.registerEnabledGrouping.ref}
              onChange={registerFields.registerEnabledGrouping.onChange}
              i18nLabel={words.groupBy}
            />

            <ProformaInvoiceFormGroupBySelectField
              control={control}
              data={groupByOptions[watchFields.watchViewMode]}
              name={InvoiceItemsViewFields.GROUP_BY}
              classNamePrefix="av_select"
            />
          </div>
        </CheckPermissions>
      </div>

      {invoice?.avInvoiceBillingInfo?.paymentMethod ===
        AvBillingInfoPaymentMethods.WIRE && (
        <div className="flex items-center bg-gray-50 dark:bg-gray-850 rounded-md py-2 px-4">
          <CheckPermissions
            action={
              thirdPersonView
                ? self
                  ? TeamsPermissions.READ_SELF_PAYMENTS_INVOICE_CURRENCY
                  : TeamsPermissions.READ_OTHER_PAYMENTS_INVOICE_CURRENCY
                : InvoicesPermissions.READ_PAYMENTS_INVOICE_CURRENCY
            }
          >
            <div className="flex gap-x-2 items-center relative z-10">
              <CurrencySelectField<InvoiceItemsViewData>
                inputWrapperClassName="h-full border-transparent bg-transparent text-gray-900 rounded-md text-xs"
                classNamePrefix="av_select"
                control={control}
                name={ProformaInvoiceFormFields.CURRENCY}
                onChange={handleChangeCurrency}
              />
              <ExchangeRatesListTooltipIconButton
                currencies={baseCurrenciesList}
              />
            </div>
          </CheckPermissions>
        </div>
      )}
    </div>
  );
}

export default InvoiceItemsViewHeader;
