import { useCallback, useMemo } from 'react';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';
import isArray from 'lodash/isArray';

import {
  FetchItemsCheckedHash,
  FetchItemsOnSetCheckedIds
} from '../../../../../../../../items/itemsTypes';

interface ProformaInvoiceFormItemsViewDataTableOptions {
  checkedHash: FetchItemsCheckedHash;
  items:
    | {
        groupedId: string;
      }[]
    | { groupedId: string };
  onCheck: FetchItemsOnSetCheckedIds;
}

function useProformaInvoiceFormItemsViewDataTable({
  checkedHash,
  items,
  onCheck
}: ProformaInvoiceFormItemsViewDataTableOptions) {
  const handleCheckItems = useCallback<(groupedId: string) => void>(
    (groupedId) => {
      onCheck(groupedId.split(','));
    },
    [onCheck]
  );

  const checkboxState = useMemo<boolean>(() => {
    const itemIds = flatMap(
      isArray(items)
        ? items.map((item) => item.groupedId.split(','))
        : items.groupedId.split(',')
    );

    if (isEmpty(itemIds)) return false;

    if (every(itemIds, (itemId) => checkedHash[itemId])) {
      return true;
    }

    if (every(itemIds, (itemId) => !checkedHash[itemId])) {
      return false;
    }

    return undefined;
  }, [checkedHash, items]);

  return {
    checkboxState,
    handleCheckItems
  };
}

export default useProformaInvoiceFormItemsViewDataTable;
