import { useCallback, useMemo } from 'react';
import { Control, FieldPath } from 'react-hook-form';
import cl from 'classnames';

import {
  ClassName,
  ErrorMessage,
  I18nText,
  IsDisabled
} from '../../../../../../../../types';
import {
  FormatGroupLabelType,
  FormatOptionLabelType,
  MultiSelectDataType,
  MultiSelectGroupedDataType
} from '../../../../../../../../helpers/MultiSelect/types';

import {
  GroupedMultiSelectField,
  GroupedMultiSelectFieldChangeCallbackType
} from '../../../../../../../../helpers/FormFields/GroupedMultiSelectField';

export interface ProformaInvoiceFormGroupBySelectFieldProps<T> {
  autoFocus?: boolean;
  classNamePrefix?: string;
  control: Control<T>;
  disabled?: IsDisabled;
  errorMessage?: ErrorMessage;
  i18nLabel?: I18nText;
  i18nPlaceholder?: I18nText;
  inputWrapperClassName?: ClassName;
  isLoading?: boolean;
  name: FieldPath<T>;
  onBlur?: () => void;
  onChange?: GroupedMultiSelectFieldChangeCallbackType;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  openMenuOnFocus?: boolean;
  menuPosition?: 'absolute' | 'fixed';
  data?: MultiSelectGroupedDataType[];
}

export function ProformaInvoiceFormGroupBySelectField<T>({
  autoFocus,
  classNamePrefix,
  control,
  disabled,
  errorMessage,
  i18nLabel,
  i18nPlaceholder,
  inputWrapperClassName,
  isLoading,
  name,
  onBlur,
  onChange,
  onMenuClose,
  onMenuOpen,
  openMenuOnFocus,
  menuPosition = 'fixed',
  data
}: ProformaInvoiceFormGroupBySelectFieldProps<T>) {
  const handleOnChange = useCallback<GroupedMultiSelectFieldChangeCallbackType>(
    (newValue) => onChange?.(newValue),
    [onChange]
  );

  const firstGroup = useMemo(() => data[0], [data]);

  const formatGroupLabel = useCallback<FormatGroupLabelType>(
    (group) => (
      <div>
        <div
          className={cl(
            group.options !== (firstGroup.options as any) &&
              'my-1 border-b border-gray-600'
          )}
        ></div>
      </div>
    ),
    [firstGroup.options]
  );

  const formatOptionLabel = useCallback<FormatOptionLabelType>(
    (option: MultiSelectDataType, { context }) => {
      if (context === 'menu') {
        return <span className="block truncate">{option.label}</span>;
      }

      return option.label;
    },
    []
  );

  return (
    <GroupedMultiSelectField<T>
      autoFocus={autoFocus}
      classNamePrefix={
        classNamePrefix || (errorMessage ? 'av_select_error' : 'av_select')
      }
      control={control}
      data={data}
      disabled={disabled}
      emptyValue={undefined}
      error={errorMessage}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      isLoading={isLoading}
      isSearchable
      labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
      menuPosition={menuPosition}
      multi={false}
      name={name}
      onBlur={onBlur}
      onChange={handleOnChange}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      openMenuOnFocus={openMenuOnFocus}
    />
  );
}

export default ProformaInvoiceFormGroupBySelectField;
