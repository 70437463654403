import { Controller } from 'react-hook-form';
import { useCallback } from 'react';

import { ProformaInvoiceFormSelectFieldControl } from './components/ProformaInvoiceFormSelectFieldControl';
import {
  MultiSelectFieldControlProps,
  MultiSelectFieldProps
} from './ProformaInvoiceFormSelectField.types';
import { ProformaInvoiceFormMultiSelectValueType } from './components/ProformaInvoiceFormSelectFieldControl/ProformaInvoiceFormSelectFieldControl.types';

function ProformaInvoiceFormSelectField<FormDataType>({
  control,
  disabled,
  name,
  i18nLabel,
  i18nPlaceholder,
  labelClassName,
  inputWrapperClassName,
  error,
  defaultValue,
  menuPosition = 'fixed',
  classNamePrefix,
  onChange: handleChange,
  data
}: MultiSelectFieldControlProps<FormDataType> &
  MultiSelectFieldProps<FormDataType>) {
  const onChangeControl = useCallback(
    (value: string) => {
      handleChange?.(value);
    },
    [handleChange]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, name },
        fieldState: { error: fieldError }
      }) => (
        <ProformaInvoiceFormSelectFieldControl
          data={data}
          defaultValue={defaultValue}
          disabled={disabled}
          error={fieldError?.message || error}
          i18nLabel={i18nLabel}
          i18nPlaceholder={i18nPlaceholder}
          labelClassName={labelClassName}
          multi={false}
          onChange={(value) => {
            onChange(value);
            onChangeControl?.(value);
          }}
          placeholder={i18nLabel}
          value={value as ProformaInvoiceFormMultiSelectValueType}
          name={name}
          classNamePrefix={classNamePrefix || 'av_select'}
          inputWrapperClassName={inputWrapperClassName || 'w-full'}
          isSearchable
          menuPosition={menuPosition}
        />
      )}
    />
  );
}

export default ProformaInvoiceFormSelectField;
