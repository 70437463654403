import { I18nText } from '../../../../../../types';

import { InvoiceItemsViewTableHeader } from '../InvoiceItemsViewTableHeader';
import { InvoiceItemsViewTableBody } from '../InvoiceItemsViewTableBody';
import { InvoiceItemsViewTableFooter } from '../InvoiceItemsViewTableFooter';

interface InvoiceItemsViewTableProps {
  i18nInvoiceTotal?: I18nText;
  withoutPayments?: boolean;
  withPrepayment?: boolean;
  readonly?: boolean;
}

function InvoiceItemsViewTable({
  i18nInvoiceTotal,
  withoutPayments,
  withPrepayment,
  readonly
}: InvoiceItemsViewTableProps) {
  return (
    <div className="flex">
      <div className="align-middle inline-block min-w-full relative">
        <table className="w-full font-mono">
          <InvoiceItemsViewTableHeader />

          <InvoiceItemsViewTableBody />

          <InvoiceItemsViewTableFooter
            i18nInvoiceTotal={i18nInvoiceTotal}
            withoutPayments={withoutPayments}
            withPrepayment={withPrepayment}
            readonly={readonly}
          />
        </table>
      </div>
    </div>
  );
}

export default InvoiceItemsViewTable;
