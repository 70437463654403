import cl from 'classnames';

import { ClassName } from '../../../../types';
import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOptions,
  UpdateInvoiceCustomFieldCacheKeys
} from '../../invoiceCustomFieldsTypes';
import { CompanyID } from '../../../companies/companiesTypes';

import { UpdateInvoiceCustomFieldModalButton } from '../../components/modalButtons/UpdateInvoiceCustomFieldModalButton';

interface InvoiceCustomFieldProps {
  className?: ClassName;
  labelClassName?: ClassName;
  bodyClassName?: ClassName;
  customFieldOptions: InvoiceCustomFieldOptions;
  customFieldLabel: InvoiceCustomFieldLabel;
  customFieldBody: InvoiceCustomFieldBody;
  customFieldId?: InvoiceCustomFieldID;
  customFieldLocation?: InvoiceCustomFieldLocation;
  cacheKeys?: UpdateInvoiceCustomFieldCacheKeys;
  companyId?: CompanyID;
  withoutLabel?: boolean;
  withoutLocation?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
}

function InvoiceCustomField({
  className,
  labelClassName,
  bodyClassName,
  customFieldOptions,
  customFieldLabel,
  customFieldBody,
  customFieldId,
  customFieldLocation,
  cacheKeys,
  companyId,
  withoutLabel,
  withoutLocation,
  canUpdate,
  canDelete
}: InvoiceCustomFieldProps) {
  return (
    <div className={className || 'text-xs relative group'}>
      <label className={labelClassName || 'font-semibold mr-1'}>
        {customFieldLabel}
        {customFieldBody && ':'}
      </label>
      <div
        className={cl(
          bodyClassName || 'text-gray-600 dark:text-gray-400',
          customFieldOptions?.multiline ? 'whitespace-pre-line' : 'inline'
        )}
      >
        {customFieldBody}
      </div>
      {canUpdate && (
        <div className="absolute -left-6 top-0 h-full w-6 pr-1">
          <UpdateInvoiceCustomFieldModalButton
            cacheKeys={cacheKeys}
            companyId={companyId}
            invoiceCustomFieldId={customFieldId}
            label={customFieldLabel}
            body={customFieldBody}
            location={customFieldLocation}
            options={customFieldOptions}
            withoutLabel={withoutLabel}
            withoutLocation={withoutLocation}
            canDelete={canDelete}
          />
        </div>
      )}
    </div>
  );
}

export default InvoiceCustomField;
