import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation
} from '../../../invoiceCustomFieldsTypes';
import {
  CustomFieldValueID,
  CustomFieldValueText
} from '../../../../customFieldValues/customFieldValuesTypes';
import { CustomFieldsSelectOptionType } from '../../../../customFields/helpers/CustomFieldsSelectField';

export type CreateInvoiceCustomFieldFormData = {
  label: InvoiceCustomFieldLabel;
  body?: InvoiceCustomFieldBody;
  location: InvoiceCustomFieldLocation;
  options?: {
    multiline?: boolean;
  };
  saveInCompany?: boolean;

  customField?: Partial<CustomFieldsSelectOptionType>;
  customFieldValue?: {
    value: CustomFieldValueID;
    label: CustomFieldValueText;
  };
};

export const enum CreateInvoiceCustomFieldFormTabs {
  EXISTING_FIELD = 'existing-field',
  NEW_FIELD = 'new-field'
}
