import { gql } from 'graphql-request';

import {
  GroupedItemBatchLabel,
  GroupedItemConvertedPrice,
  GroupedItemConvertedPricePerItem,
  GroupedItemInvoiceStatus,
  GroupedItemItemCategoryName,
  GroupedItemItemCreatedAt,
  GroupedItemItemID,
  GroupedItemItemName,
  GroupedItemProjectClosureMonth,
  GroupedItemProjectCreatedAt,
  GroupedItemProjectName,
  GroupedItemProjectNanoID,
  GroupedItemQuantity,
  GroupedItemSplitPartNumber,
  GroupedItemSplitPartPercent,
  GroupedItemTaskCreatedAt,
  GroupedItemTaskDueDateAt,
  GroupedItemTaskName,
  GroupedItemTaskNanoID,
  GroupedItemTaskStatus
} from '../groupedItemsTypes';
import {
  GroupedItemSmartContractTaskProjectId,
  GroupedItemSmartContractTaskProjectName,
  InvoiceClientId,
  InvoiceCreatedAt,
  InvoiceID,
  InvoiceInvoiceType,
  InvoiceName,
  InvoiceNanoID,
  InvoiceStatus
} from '../../invoices/invoicesTypes';
import { SmartContractStatus } from '../../smartContracts/smartContractsTypes';
import {
  ProjectOwnerClient,
  ProjectOwnerCurrentTeamNanoID,
  ProjectOwnerFullName,
  ProjectOwnerNanoID
} from '../../projects/projectsTypes';
import {
  TaskOwnerClient,
  TaskOwnerCurrentTeamNanoID,
  TaskOwnerFullName,
  TaskOwnerNanoID
} from '../../tasks/tasksTypes';
import { MayBe } from '../../../types';

export interface FetchedGroupedInvoiceItemsQueryResponse {
  batchLabel: GroupedItemBatchLabel;
  convertedPrice: GroupedItemConvertedPrice;
  convertedPricePerItem: GroupedItemConvertedPricePerItem;
  itemCategoryName: GroupedItemItemCategoryName;
  itemCreatedAt: GroupedItemItemCreatedAt;
  itemId: GroupedItemItemID;
  itemName: GroupedItemItemName;
  items: {
    id: GroupedItemItemID;
    convertedPrice: GroupedItemConvertedPrice;
    invoices: {
      id: InvoiceID;
      status: InvoiceStatus;
      invoiceType: InvoiceInvoiceType;
    }[];
    smartContract: {
      status: SmartContractStatus;
      task: {
        project: {
          id: GroupedItemSmartContractTaskProjectId;
          name: GroupedItemSmartContractTaskProjectName;
        };
      };
    };
    tempInvoices: {
      id: InvoiceID;
      nanoId: InvoiceNanoID;
      clientId: InvoiceClientId;
      name: InvoiceName;
      invoiceType: InvoiceInvoiceType;
      status: InvoiceStatus;
    }[];
  }[];
  invoiceStatus: GroupedItemInvoiceStatus;
  invoiceCreationMonth: InvoiceCreatedAt;
  projectCreatedAt: GroupedItemProjectCreatedAt;
  projectName: GroupedItemProjectName;
  projectNanoId: GroupedItemProjectNanoID;
  projectClosureMonth: GroupedItemProjectClosureMonth;
  projectOwner?: {
    nanoId: ProjectOwnerNanoID;
    fullName: ProjectOwnerFullName;
    client?: ProjectOwnerClient;
    currentTeam?: MayBe<{
      nanoId: ProjectOwnerCurrentTeamNanoID;
    }>;
  };
  splitPartNumber: GroupedItemSplitPartNumber;
  splitPartPercent: GroupedItemSplitPartPercent;
  taskCreatedAt: GroupedItemTaskCreatedAt;
  taskDueDateAt: GroupedItemTaskDueDateAt;
  taskDueDateMonth: GroupedItemTaskCreatedAt;
  taskDoneMonth: GroupedItemTaskCreatedAt;
  taskName: GroupedItemTaskName;
  taskNanoId: GroupedItemTaskNanoID;
  taskOwner?: {
    nanoId: TaskOwnerNanoID;
    fullName: TaskOwnerFullName;
    client?: TaskOwnerClient;
    currentTeam?: MayBe<{
      nanoId: TaskOwnerCurrentTeamNanoID;
    }>;
  };
  taskStatus: GroupedItemTaskStatus;
  quantity: GroupedItemQuantity;
}

export const FETCH_GROUPED_INVOICE_ITEMS_QUERY = gql`
  query GroupedItemsForInvoice(
    $filters: GroupedItemsFilters
    $groupBy: [ItemGroupBy!]!
    $groupItemsByType: Boolean
    $limit: Int
    $offset: Int
    $showTotal: Boolean
    $sort: [GroupedItemsSortEnum!]
    $convertTo: Currency
    $currencyRateId: ID
  ) {
    groupedItems(
      filters: $filters
      groupBy: $groupBy
      groupItemsByType: $groupItemsByType
      sort: $sort
      showTotal: $showTotal
      limit: $limit
      offset: $offset
      convertTo: $convertTo
      currencyRateId: $currencyRateId
    ) {
      appVersion
      nodes {
        convertedPrice
        convertedPricePerItem
        itemCategoryName
        itemCreatedAt
        itemId
        itemName
        items {
          id
          convertedPrice
          smartContract {
            status
            task {
              project {
                id
                name
              }
            }
          }
          invoices {
            id
            invoiceType
            status
          }
          tempInvoices {
            id
            nanoId
            clientId
            name
            invoiceType
            status
          }
        }
        invoiceStatus
        invoiceCreationMonth
        projectCreatedAt
        projectName
        projectNanoId
        projectClosureMonth
        projectOwner {
          nanoId
          fullName
          client
          currentTeam {
            nanoId
          }
        }
        splitLabel
        splitPartNumber
        splitPartPercent
        taskCreatedAt
        taskDueDateAt
        taskDueDateMonth
        taskDoneMonth
        taskName
        taskNanoId
        taskStatus
        taskOwner {
          nanoId
          fullName
          client
          currentTeam {
            nanoId
          }
        }
        quantity
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
