import { gql } from 'graphql-request';
import {
  CustomFieldValueCompanyID,
  CustomFieldValueDefaultAt,
  CustomFieldValueID,
  CustomFieldValueText
} from '../customFieldValuesTypes';

export interface FetchCustomFieldValuesQueryResponse {
  companyId: CustomFieldValueCompanyID;
  defaultAt: CustomFieldValueDefaultAt;
  id: CustomFieldValueID;
  text: CustomFieldValueText;
}

export const FETCH_CUSTOM_FIELD_VALUES_QUERY = gql`
  query CustomFieldValues(
    $filters: CustomFieldValuesFilters
    $limit: Int
    $offset: Int
    $sort: [CustomFieldValuesSortEnum!]
  ) {
    customFieldValues(
      filters: $filters
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      nodes {
        companyId
        defaultAt
        id
        text
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
