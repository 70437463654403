import { useMemo } from 'react';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';

import { InvoiceItemsViewItem } from '../useInvoiceItemsViewContext';

interface InvoiceItemsViewPreparedItemsOptions {
  items: InvoiceItemsViewItem[];
}

function useInvoiceItemsViewPreparedItems({
  items
}: InvoiceItemsViewPreparedItemsOptions) {
  const preparedItems = useMemo(
    () =>
      map(items, (groupedItem) => ({
        ...groupedItem,
        id: groupedItem.itemId,
        groupedId: groupedItem.items?.map((item) => item.id)?.join()
      })),
    [items]
  );

  const flatItems = useMemo(
    () =>
      flatMap(
        map(items, (groupedItem) =>
          map(groupedItem.items, (item) => ({
            ...groupedItem,
            ...item,
            itemId: item.id,
            groupedId: item.id
          }))
        )
      ),
    [items]
  );

  return {
    preparedItems,
    flatItems
  };
}

export default useInvoiceItemsViewPreparedItems;
