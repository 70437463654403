import zipObject from 'lodash/zipObject';
import compact from 'lodash/compact';

import {
  FetchGroupedItemsSortTypes,
  GroupedItemsGroupBy
} from '../../../../../../groupedItems/groupedItemsTypes';
import uniq from 'lodash/uniq';

export function getDefaultGroupedItemsSort(groupBy: GroupedItemsGroupBy[]) {
  const itemsGroupBy = zipObject(groupBy, Array(groupBy.length).fill(true));

  return uniq(
    compact([
      (itemsGroupBy.task_due_month &&
        FetchGroupedItemsSortTypes.TASK_DUE_DATE_MONTH_ASC) ||
        (itemsGroupBy.project &&
          !itemsGroupBy.project_closure_month &&
          FetchGroupedItemsSortTypes.PROJECT_CREATED_AT_ASC) ||
        (itemsGroupBy.task && FetchGroupedItemsSortTypes.TASK_CREATED_AT_ASC) ||
        (itemsGroupBy.category &&
          FetchGroupedItemsSortTypes.CATEGORY_NAME_ASC) ||
        (itemsGroupBy.batch &&
          FetchGroupedItemsSortTypes.ITEM_CREATED_AT_DESC) ||
        (!itemsGroupBy.project_closure_month &&
          FetchGroupedItemsSortTypes.ITEM_CREATED_AT_ASC),
      (itemsGroupBy.project_closure_month &&
        FetchGroupedItemsSortTypes.PROJECT_CLOSURE_MONTH_DESC) ||
        (itemsGroupBy.project &&
          FetchGroupedItemsSortTypes.PROJECT_CREATED_AT_ASC) ||
        (itemsGroupBy.task && FetchGroupedItemsSortTypes.TASK_CREATED_AT_ASC) ||
        (itemsGroupBy.task_due_month &&
          FetchGroupedItemsSortTypes.TASK_CREATED_AT_ASC) ||
        (itemsGroupBy.category && FetchGroupedItemsSortTypes.CATEGORY_NAME_ASC)
    ])
  );
}
