import { useMemo } from 'react';

import { ProformaInvoiceFormGroupByMode } from '../../../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';

import {
  InvoiceItemsViewItemsType,
  useInvoiceItemsViewContext
} from '../../hooks/useInvoiceItemsViewContext';
import { useProformaInvoiceFormItemsViewDataTable } from '../../../../../teams/components/forms/ProformaInvoiceForm/components/ProformaInvoiceFormItemsView/hooks/useProformaInvoiceFormItemsViewDataTable';
import { useInvoiceItemsViewColumns } from '../../hooks/useInvoiceItemsViewColumns';

import { IndexTableThreeStatesCheckbox } from '../../../../../common/components/tables/IndexTableThreeStatesCheckbox';

import { DateHelper } from '../../../../../../helpers/DateHelper';
import { NextPureLinkHelper } from '../../../../../../helpers/links/NextPureLinkHelper';

import { TaskPath } from '../../../../../tasks/TaskPath';
import { ProjectPath } from '../../../../../projects/ProjectPath';
import { Translate } from '../../../../../../helpers/Translate';

import { words } from '../../../../../../locales/keys';
import { Icon } from '../../../../../../helpers/Icon';
import { IconsEnum } from '../../../../../../assets/icons/types';

interface InvoiceItemsViewTableGroupRowsHeaderProps {
  items: InvoiceItemsViewItemsType;
}

const headerDateFormat = 'MMMM yyyy';

function InvoiceItemsViewTableGroupRowsHeader({
  items
}: InvoiceItemsViewTableGroupRowsHeaderProps) {
  const { checkedHash, handleSetCheckedIds, watchFields } =
    useInvoiceItemsViewContext();

  const { checkboxState, handleCheckItems } =
    useProformaInvoiceFormItemsViewDataTable({
      items: items,
      checkedHash,
      onCheck: handleSetCheckedIds
    });

  const { showCheckboxCol } = useInvoiceItemsViewColumns();

  const groupedId = items.map((item) => item.groupedId).join(',');

  const item = items?.[0];

  const headerGroup = useMemo(() => {
    switch (watchFields.watchGroupBy) {
      case ProformaInvoiceFormGroupByMode.TASK:
        return (
          <NextPureLinkHelper
            className="hover:underline"
            href={TaskPath.show(item.taskNanoId)}
            text={item.taskName}
          />
        );

      case ProformaInvoiceFormGroupByMode.PROJECT_TASK:
        return (
          <div>
            <NextPureLinkHelper
              className="hover:underline"
              href={ProjectPath.show(item.projectNanoId)}
              text={item.projectName}
            />
            <Icon
              icon={IconsEnum.ARROW_NARROW_RIGHT_SOLID}
              className="h-4 w-4 inline mx-0.5"
            />
            <NextPureLinkHelper
              className="hover:underline"
              href={TaskPath.show(item.taskNanoId)}
              text={item.taskName}
            />
          </div>
        );

      case ProformaInvoiceFormGroupByMode.PROJECT:
        return (
          <NextPureLinkHelper
            className="hover:underline"
            href={ProjectPath.show(item.projectNanoId)}
            text={item.projectName}
          />
        );

      case ProformaInvoiceFormGroupByMode.MONTH_INVOICE_CREATED:
        return (
          <DateHelper
            date={item.invoiceCreationMonth}
            customFormat={headerDateFormat}
          />
        );

      case ProformaInvoiceFormGroupByMode.MONTH_TASK_CREATED:
        return (
          <DateHelper
            date={item.taskCreatedAt}
            customFormat={headerDateFormat}
          />
        );

      case ProformaInvoiceFormGroupByMode.MONTH_TASK_DUE_DATE:
        return (
          <DateHelper
            date={item.taskDueDateMonth}
            customFormat={headerDateFormat}
          />
        );

      case ProformaInvoiceFormGroupByMode.MONTH_TASK_DONE:
        return item.taskDoneMonth ? (
          <DateHelper
            date={item.taskDoneMonth}
            customFormat={headerDateFormat}
          />
        ) : (
          <Translate id={words.na} />
        );

      case ProformaInvoiceFormGroupByMode.MONTH_PROJECT_CLOSED:
        return item.projectClosureMonth ? (
          <DateHelper
            date={item.projectClosureMonth}
            customFormat={headerDateFormat}
          />
        ) : (
          <Translate id={words.na} />
        );

      case ProformaInvoiceFormGroupByMode.MONTH_PROJECT_CREATED:
        return (
          <DateHelper
            date={item.projectCreatedAt}
            customFormat={headerDateFormat}
          />
        );

      // case ProformaInvoiceFormGroupByMode.INVOICE_TAG:
      //   break;

      default:
        return '';
    }
  }, [
    item.invoiceCreationMonth,
    item.projectClosureMonth,
    item.projectCreatedAt,
    item.projectName,
    item.projectNanoId,
    item.taskCreatedAt,
    item.taskDoneMonth,
    item.taskDueDateMonth,
    item.taskName,
    item.taskNanoId,
    watchFields.watchGroupBy
  ]);

  return (
    <tr>
      {/* Checkbox */}
      {showCheckboxCol && (
        <td className="text-xs p-0 h-0 pt-2 tracking-tight">
          <div className="flex h-full items-center py-1 px-3 bg-gray-100 dark:bg-gray-800 sm:-ml-2 pl-3 rounded-l-md sm:-mr-2 pr-3 rounded-r-md">
            <IndexTableThreeStatesCheckbox
              checked={checkboxState}
              className="flex items-center justify-center"
              checkBoxClassName="basic-checkbox"
              itemId={groupedId}
              indeterminate={checkboxState === undefined}
              onCheck={handleCheckItems}
              scope="proforma-invoice-items"
            />
          </div>
        </td>
      )}

      <td colSpan={99} className="text-xs p-0 h-0 pt-2 tracking-tight">
        <div className="h-full items-center py-1 px-3 bg-gray-100 dark:bg-gray-800 sm:-ml-2 pl-3 rounded-l-md sm:-mr-2 pr-3 rounded-r-md">
          {headerGroup}
        </div>
      </td>
    </tr>
  );
}

export default InvoiceItemsViewTableGroupRowsHeader;
