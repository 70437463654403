import { useEffect } from 'react';

import { InvoiceViewItemsTableCheckedItemsType } from '../useInvoiceItemsViewContext';

import { useTableCheckable } from '../../../../../../common/hooks/useTableCheckable';
import { useReactQueryState } from '../../../../../common/hooks/base/reactQuery/useReactQueryState';
import { usePreviousValue } from '../../../../../../common/hooks/usePreviousValue';

import { invoiceItemsViewCheckedItemsKey } from '../../../../invoicesConstants';

interface InvoiceItemsViewCheckableOptions {
  items: InvoiceViewItemsTableCheckedItemsType;
}

function useInvoiceItemsViewCheckable({
  items
}: InvoiceItemsViewCheckableOptions) {
  const {
    checkedHash,
    checkedAll,
    checkedItems,
    checkedPartial,
    handleSetCheckedIds,
    handleCheckAll,
    handleUncheckAll
  } = useTableCheckable({
    items
  });

  const { setValue: setCheckedItemsState } = useReactQueryState(
    invoiceItemsViewCheckedItemsKey,
    {
      checkedHash,
      checkedAll,
      checkedItems,
      checkedPartial,
      handleSetCheckedIds,
      handleCheckAll,
      handleUncheckAll
    }
  );

  const prevCheckedHash = usePreviousValue(checkedHash);

  useEffect(() => {
    prevCheckedHash !== checkedHash &&
      setCheckedItemsState({
        checkedHash,
        checkedAll,
        checkedItems,
        checkedPartial,
        handleSetCheckedIds,
        handleCheckAll,
        handleUncheckAll
      });
  }, [prevCheckedHash, checkedHash]);

  return {
    checkedAll,
    checkedPartial,
    checkedHash,
    checkedItems,
    handleCheckAll,
    handleUncheckAll,
    handleSetCheckedIds
  };
}

export default useInvoiceItemsViewCheckable;
