import { ReactNode, useMemo } from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import { ClassName } from '../../../../types';
import { CustomFieldLocations } from '../../../customFields/customFieldsTypes';
import {
  FetchInvoiceCustomFieldsCacheKeys,
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOptions,
  InvoiceCustomFieldOrder
} from '../../invoiceCustomFieldsTypes';
import { InvoiceID } from '../../../invoices/invoicesTypes';
import { CompanyID } from '../../../companies/companiesTypes';

import { InvoiceCustomField } from '../InvoiceCustomField';
import { CreateInvoiceCustomFieldModalButton } from '../../components/modalButtons/CreateInvoiceCustomFieldModalButton';

interface InvoiceCustomFieldsListProps {
  className?: ClassName;
  listClassName?: ClassName;
  fieldClassName?: ClassName;
  labelClassName?: ClassName;
  bodyClassName?: ClassName;
  createButtonClassName?: ClassName;
  cacheKeys?: FetchInvoiceCustomFieldsCacheKeys;
  invoiceId?: InvoiceID;
  companyId?: CompanyID;
  invoiceCustomFields: {
    id: InvoiceCustomFieldID;
    label: InvoiceCustomFieldLabel;
    body: InvoiceCustomFieldBody;
    location: InvoiceCustomFieldLocation;
    order: InvoiceCustomFieldOrder;
    options: InvoiceCustomFieldOptions;
  }[];
  customFieldsLocation: CustomFieldLocations;
  withoutLabel?: boolean;
  withoutLocation?: boolean;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  beforeChildren?: ReactNode;
}

function InvoiceCustomFieldsList({
  className,
  listClassName,
  fieldClassName,
  labelClassName,
  bodyClassName,
  createButtonClassName,
  cacheKeys,
  invoiceId,
  companyId,
  invoiceCustomFields,
  customFieldsLocation,
  withoutLabel,
  withoutLocation,
  canCreate,
  canUpdate,
  canDelete,
  beforeChildren
}: InvoiceCustomFieldsListProps) {
  const invoiceLocationCustomFields = useMemo(
    () =>
      sortBy(
        filter(invoiceCustomFields, ['location', customFieldsLocation]),
        'order'
      ),
    [customFieldsLocation, invoiceCustomFields]
  );

  if (isEmpty(invoiceLocationCustomFields) && !canCreate) return null;

  return (
    <div className={className}>
      {beforeChildren}

      {!isEmpty(invoiceLocationCustomFields) && (
        <div className={listClassName}>
          {map(invoiceLocationCustomFields, (field, index) => (
            <InvoiceCustomField
              key={index}
              className={fieldClassName}
              labelClassName={labelClassName}
              bodyClassName={bodyClassName}
              customFieldBody={field.body}
              customFieldLabel={field.label}
              customFieldOptions={field.options}
              customFieldLocation={field.location}
              customFieldId={field.id}
              cacheKeys={cacheKeys}
              canUpdate={canUpdate}
              canDelete={canDelete}
              withoutLocation={withoutLocation}
              withoutLabel={withoutLabel}
            />
          ))}
        </div>
      )}

      {canCreate && (
        <div className="mt-1 flex align-center text-xs text-blue-600">
          <CreateInvoiceCustomFieldModalButton
            className={createButtonClassName}
            invoiceId={invoiceId}
            companyId={companyId}
            customFieldsLocation={customFieldsLocation}
            withoutLabel={withoutLabel}
            withoutLocation={withoutLocation}
            cacheKeys={cacheKeys}
          />
        </div>
      )}
    </div>
  );
}

export default InvoiceCustomFieldsList;
