import {
  CreateItemCacheKeys,
  CreateItemGqlQuery,
  CreatedAt,
  DateFilterType,
  DeleteItemCacheKeys,
  DeleteItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  ID,
  IdFilterType,
  NanoIdFilterType,
  TextFilterType,
  UUID,
  UpdateItemCacheKeys,
  UpdateItemGqlQuery,
  UpdatedAt,
  UuidFilterType
} from '../../types';
import {
  CustomFieldLocations,
  CustomFieldOptions
} from '../customFields/customFieldsTypes';
import { InvoiceID } from '../invoices/invoicesTypes';
import { UserID } from '../users/usersTypes';

export type InvoiceCustomFieldID = ID;
export type InvoiceCustomFieldUUID = UUID;
export type InvoiceCustomFieldBody = string;
export type InvoiceCustomFieldLabel = string;
export type InvoiceCustomFieldLocation = CustomFieldLocations;
export type InvoiceCustomFieldOptions = CustomFieldOptions;
export type InvoiceCustomFieldInvoiceID = InvoiceID;
export type InvoiceCustomFieldUserID = UserID;
export type InvoiceCustomFieldCreatedAt = CreatedAt;
export type InvoiceCustomFieldUpdatedAt = UpdatedAt;
export type InvoiceCustomFieldOrder = number;

export const enum FetchInvoiceCustomFieldsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC'
}

export interface FetchInvoiceCustomFieldsFilters {
  invoiceId?: IdFilterType;
  invoiceNanoId?: NanoIdFilterType;
  createdAt?: DateFilterType;
  id?: IdFilterType;
  location?: TextFilterType;
  updatedAt?: DateFilterType;
  userId?: IdFilterType;
  userNanoId?: NanoIdFilterType;
  uuid?: UuidFilterType;
}

export type FetchInvoiceCustomFieldsGqlQuery = FetchItemsGqlQuery;

export type FetchInvoiceCustomFieldsCacheKey = FetchItemsCacheKey;
export type FetchInvoiceCustomFieldsCacheKeys =
  FetchInvoiceCustomFieldsCacheKey[];

export type FetchInvoiceCustomFieldsSort = FetchItemsSort;
export type FetchInvoiceCustomFieldsPage = FetchItemsPage;
export type FetchInvoiceCustomFieldsLimit = FetchItemsLimit;

export type CreateInvoiceCustomFieldGqlQuery = CreateItemGqlQuery;
export type CreateInvoiceCustomFieldCacheKeys = CreateItemCacheKeys;
export type UpdateInvoiceCustomFieldGqlQuery = UpdateItemGqlQuery;
export type UpdateInvoiceCustomFieldCacheKeys = UpdateItemCacheKeys;
export type DeleteInvoiceCustomFieldGqlQuery = DeleteItemGqlQuery;
export type DeleteInvoiceCustomFieldCacheKeys = DeleteItemCacheKeys;

export const enum InvoiceCustomFieldFields {
  LABEL = 'label',
  BODY = 'body',
  LOCATION = 'location',
  VALUES = 'values',
  OPTIONS = 'options',
  OPTIONS_MULTILINE = 'options.multiline',
  SAVE_IN_COMPANY = 'saveInCompany'
}
