import { gql } from 'graphql-request';

export const UPDATE_INVOICE_CUSTOM_FIELD_QUERY = gql`
  mutation UpdateInvoiceCustomField(
    $uuid: ID!
    $body: String
    $invoiceId: ID
    $label: String
    $location: String
    $options: JSON
    $order: Float
  ) {
    updateInvoiceCustomField(
      input: {
        uuid: $uuid
        body: $body
        invoiceId: $invoiceId
        label: $label
        location: $location
        options: $options
        order: $order
      }
    ) {
      appVersion
      errors {
        fullMessages
      }
      record {
        appVersion
        body
        createdAt
        id
        invoiceId
        label
        location
        type
        updatedAt
        userId
        uuid
      }
      recordId
      recordNanoId
      recordUuid
      status
    }
  }
`;
