import { useCallback } from 'react';

import { BffApiUpdateItemError, Currencies } from '../../../../types';
import { InvoiceID, ChangeCurrencyInvoiceCacheKeys } from '../../invoicesTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';
import { CurrencyRateID } from '../../../currencyRates/currencyRatesTypes';

interface ChangeCurrencyInvoiceOptions {
  cacheKeys?: ChangeCurrencyInvoiceCacheKeys;
}

export type ChangeCurrencyInvoiceInputData = {
  actionOptions: {
    url: string;
  };
} & Pick<ChangeCurrencyInvoiceInput, 'invoice'>;

export type ChangeCurrencyInvoiceInput = {
  invoiceId: InvoiceID;
  invoice: {
    selectedCurrency?: Currencies;
    currencyRateId?: CurrencyRateID;
  };
};

export type ChangeCurrencyInvoiceError = BffApiUpdateItemError;

function useChangeCurrencyInvoice<ChangeCurrencyInvoiceResponseType>({
  cacheKeys
}: ChangeCurrencyInvoiceOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    ChangeCurrencyInvoiceInputData,
    ChangeCurrencyInvoiceResponseType,
    ChangeCurrencyInvoiceError
  >({ cacheKeys });

  const changeCurrency = useCallback<
    (input: ChangeCurrencyInvoiceInput) => Promise<unknown>
  >(
    (input) =>
      postQuery({
        invoice: input.invoice,
        actionOptions: {
          url: InvoiceBffUrl.changeCurrency(input.invoiceId)
        }
      }),
    [postQuery]
  );

  return {
    changeCurrencyData: postQueryData,
    changeCurrencyError: postQueryError,
    changeCurrencyLoading: postQueryLoading,
    changeCurrencyErrorMessage: postQueryErrorMessage,
    changeCurrency,
    changeCurrencyReset: postQueryReset
  };
}

export default useChangeCurrencyInvoice;
