import { useState } from 'react';
import { useQueryClient } from 'react-query';
import map from 'lodash/map';

import { CompanyID } from '../../../../../../companies/companiesTypes';
import {
  CreateInvoiceCustomFieldFormData,
  CreateInvoiceCustomFieldFormTabs
} from '../../CreateInvoiceCustomFieldForm.types';
import {
  InvoiceCustomFieldFields,
  CreateInvoiceCustomFieldCacheKeys,
  InvoiceCustomFieldLocation
} from '../../../../../invoiceCustomFieldsTypes';
import { InvoiceID } from '../../../../../../invoices/invoicesTypes';
import {
  CustomFieldFields,
  CustomFieldLocations
} from '../../../../../../customFields/customFieldsTypes';
import { CREATE_CUSTOM_FIELD_VALUE_QUERY } from '../../../../../../customFieldValues/queries/createCustomFieldValue.query';
import { CREATE_CUSTOM_FIELD_QUERY } from '../../../../../../customFields/queries/createCustomField.query';
import { CREATE_INVOICE_CUSTOM_FIELD_QUERY } from '../../../../../queries/createInvoiceCustomField.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCreateInvoiceCustomField } from '../../../../../hooks/useCreateInvoiceCustomField';
import { useCreateCustomField } from '../../../../../../customFields/hooks/useCreateCustomField';
import { useCreateCustomFieldValue } from '../../../../../../customFieldValues/hooks/useCreateCustomFieldValue';

import { createInvoiceCustomFieldFormSchema } from './useCreateInvoiceCustomFieldForm.schema';

interface CreateInvoiceCustomFieldFormProps {
  cacheKeys?: CreateInvoiceCustomFieldCacheKeys;
  location?: InvoiceCustomFieldLocation;
  invoiceId: InvoiceID;
  companyId: CompanyID;
  withoutLabel?: boolean;
}

const defaultInvoiceCustomFieldValues: CreateInvoiceCustomFieldFormData = {
  label: '',
  body: '',
  options: { multiline: false },
  location: CustomFieldLocations.HEADER,
  saveInCompany: false,
  customField: null,
  customFieldValue: null
};

function useCreateInvoiceCustomFieldForm({
  cacheKeys = [],
  location,
  invoiceId,
  companyId,
  withoutLabel
}: CreateInvoiceCustomFieldFormProps) {
  const [activeTab, setActiveTab] = useState(
    CreateInvoiceCustomFieldFormTabs.EXISTING_FIELD
  );

  const queryClient = useQueryClient();

  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    watch
  } = useReactHookForm<CreateInvoiceCustomFieldFormData>({
    defaultValues: {
      ...defaultInvoiceCustomFieldValues,
      location: location || defaultInvoiceCustomFieldValues.location
    },
    isModalForm: true,
    schema: createInvoiceCustomFieldFormSchema(activeTab, withoutLabel)
  });

  const {
    createCustomFieldReset,
    createCustomFieldLoading,
    createCustomFieldErrorMessage,
    createCustomField
  } = useCreateCustomField({
    // cacheKeys: cacheKeys,
    query: CREATE_CUSTOM_FIELD_QUERY
  });

  const {
    createCustomFieldValueReset,
    createCustomFieldValueLoading,
    createCustomFieldValueErrorMessage,
    createCustomFieldValue
  } = useCreateCustomFieldValue({
    // cacheKeys: cacheKeys,
    query: CREATE_CUSTOM_FIELD_VALUE_QUERY
  });

  const {
    createInvoiceCustomFieldReset,
    createInvoiceCustomFieldLoading,
    createInvoiceCustomFieldErrorMessage,
    createInvoiceCustomField
  } = useCreateInvoiceCustomField({
    // cacheKeys: cacheKeys,
    query: CREATE_INVOICE_CUSTOM_FIELD_QUERY
  });

  return {
    validationErrors: {
      labelValidationError: errors?.label?.message,
      locationValidationError: errors?.location?.message
    },
    control,
    createInvoiceCustomFieldReset,
    createInvoiceCustomFieldLoading,
    createInvoiceCustomFieldErrorMessage,
    createCustomFieldReset,
    createCustomFieldLoading,
    createCustomFieldErrorMessage,
    createCustomFieldValueReset,
    createCustomFieldValueLoading,
    createCustomFieldValueErrorMessage,
    resetCreateInvoiceCustomFieldForm: resetForm,
    handleSubmitReactHookForm,
    handleCreateInvoiceCustomField: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) => {
        const props =
          activeTab === CreateInvoiceCustomFieldFormTabs.EXISTING_FIELD
            ? {
                body: data.customFieldValue?.label,
                label: data.customField?.label,
                options: data.customField?.field?.options,
                location: data.customField?.field?.location
              }
            : {
                body: data.body,
                label: data.label,
                options: data.options,
                location: data.location
              };

        await createInvoiceCustomField({
          invoiceId,
          ...props
          // TODO: update when backend turns off validation
          // ...(withoutLabel
          //   ? {
          //       label: props.label
          //     }
          //   : {
          //       label: undefined
          //     })
        });

        if (
          activeTab === CreateInvoiceCustomFieldFormTabs.NEW_FIELD &&
          data.saveInCompany
        ) {
          const res = await createCustomField({
            companyId,
            location: data.location,
            options: data.options,
            body: data.body,
            // TODO: remove when backend turns off validation
            label: data.label,
            ...(withoutLabel
              ? {
                  label: data.location
                }
              : {})
          });

          const customFieldId = res.createCustomField?.recordId;

          if (customFieldId) {
            await createCustomFieldValue({
              companyId,
              customFieldId,
              text: data.body
            });
          }
        }

        map(cacheKeys, (eachCacheKey) =>
          queryClient.invalidateQueries(eachCacheKey)
        );
      }
    }),
    registerFields: {
      registerLabel: register(InvoiceCustomFieldFields.LABEL),
      registerBody: register(InvoiceCustomFieldFields.BODY),
      registerLocation: register(InvoiceCustomFieldFields.LOCATION),
      registerMultiline: register(CustomFieldFields.OPTIONS_MULTILINE),
      registerSaveInCompany: register(CustomFieldFields.SAVE_IN_COMPANY)
    },
    activeTab,
    setActiveTab,
    watchCustomFieldId: watch(CustomFieldFields.CUSTOM_FIELD)?.value,
    watchOptions: watch(CustomFieldFields.OPTIONS),
    watchSaveInCompany: watch(CustomFieldFields.SAVE_IN_COMPANY)
  };
}

export default useCreateInvoiceCustomFieldForm;
