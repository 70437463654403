import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText } from '../../../../../types';
import {
  CreateInvoiceCustomFieldCacheKeys,
  InvoiceCustomFieldLocation
} from '../../../invoiceCustomFieldsTypes';
import { InvoiceID } from '../../../../invoices/invoicesTypes';
import { CompanyID } from '../../../../companies/companiesTypes';

import { useCreateInvoiceCustomFieldForm } from '../../forms/CreateInvoiceCustomFieldForm/hooks/useCreateInvoiceCustomFieldForm';

import { CreateInvoiceCustomFieldForm } from '../../forms/CreateInvoiceCustomFieldForm';

import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { customFieldsKeys, words } from '../../../../../locales/keys';

const form = 'create-custom-field-form';

interface CreateInvoiceCustomFieldModalButtonProps {
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  cacheKeys?: CreateInvoiceCustomFieldCacheKeys;
  invoiceId: InvoiceID;
  customFieldsLocation?: InvoiceCustomFieldLocation;
  withoutLabel?: boolean;
  withoutLocation?: boolean;
  companyId: CompanyID;
}

function CreateInvoiceCustomFieldModalButton({
  className,
  i18nText,
  icon = IconsEnum.PLUS_SOLID,
  iconClassName,
  cacheKeys,
  invoiceId,
  companyId,
  customFieldsLocation,
  withoutLabel,
  withoutLocation
}: CreateInvoiceCustomFieldModalButtonProps) {
  const {
    validationErrors,
    registerFields,
    control,
    createInvoiceCustomFieldReset,
    createInvoiceCustomFieldLoading,
    createInvoiceCustomFieldErrorMessage,
    resetCreateInvoiceCustomFieldForm,
    handleCreateInvoiceCustomField,
    activeTab,
    setActiveTab,
    watchCustomFieldId,
    watchOptions,
    watchSaveInCompany
  } = useCreateInvoiceCustomFieldForm({
    invoiceId,
    companyId,
    location: customFieldsLocation,
    cacheKeys,
    withoutLabel
  });

  return (
    <FormModalButton
      form={form}
      className={
        className ||
        'p-0 space-x-1 inline-flex items-center whitespace-nowrap leading-5 focus:ring-base font-normal text-current hover:underline'
      }
      icon={icon}
      iconClassName={iconClassName || 'h-3 w-3'}
      i18nText={i18nText || customFieldsKeys.addField}
      i18nSubmitText={words.add}
      i18nTitle={customFieldsKeys.addField}
      isLoading={createInvoiceCustomFieldLoading}
      onOpen={resetCreateInvoiceCustomFieldForm}
      onClose={createInvoiceCustomFieldReset}
      onSubmit={handleCreateInvoiceCustomField}
    >
      <CreateInvoiceCustomFieldForm
        form={form}
        control={control}
        isLoading={createInvoiceCustomFieldLoading}
        registerLabel={registerFields.registerLabel}
        labelValidationError={validationErrors.labelValidationError}
        locationValidationError={validationErrors.locationValidationError}
        withoutLabel={withoutLabel}
        withoutLocation={withoutLocation}
        registerMultiline={registerFields.registerMultiline}
        registerBody={registerFields.registerBody}
        registerSaveInCompany={registerFields.registerSaveInCompany}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        companyId={companyId}
        customFieldsLocation={customFieldsLocation}
        watchCustomFieldId={watchCustomFieldId}
        watchOptions={watchOptions}
        watchSaveInCompany={watchSaveInCompany}
      />

      <AlertMessage
        addClassName="m-4"
        message={createInvoiceCustomFieldErrorMessage}
      />
    </FormModalButton>
  );
}

export default CreateInvoiceCustomFieldModalButton;
