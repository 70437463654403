import isEmpty from 'lodash/isEmpty';

import { InvoiceItemsViewInvoice } from './InvoiceItemsView.types';
import { FetchInvoicesCacheKeys, InvoiceNanoID } from '../../invoicesTypes';
import { I18nText } from '../../../../types';

import {
  InvoiceItemsViewProvider,
  useInvoiceItemsViewContext
} from './hooks/useInvoiceItemsViewContext';

import { InvoiceItemsViewHeader } from './components/InvoiceItemsViewHeader';
import { InvoiceItemsViewTable } from './components/InvoiceItemsViewTable';

import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';
import { CheckPermissions } from '../../../../helpers/CheckPermissions';
import { InvoicesPermissions } from '../../invoicesConstants';
import { TeamsPermissions } from '../../../teams/teamsConstants';

interface InvoiceItemsViewProps {
  i18nInvoiceTotal?: I18nText;
  withoutPayments?: boolean;
  withPrepayment?: boolean;
  readonly?: boolean;
}

type InvoiceItemsViewWithContextProps = InvoiceItemsViewProps & {
  invoice: InvoiceItemsViewInvoice;
  invoiceNanoId?: InvoiceNanoID;
  cacheKeys?: FetchInvoicesCacheKeys;
  thirdPersonView?: boolean;
  self?: boolean;
  customFieldsCorrectStatus?: boolean;
};

function InvoiceItemsView({
  i18nInvoiceTotal,
  withoutPayments,
  withPrepayment,
  readonly
}: InvoiceItemsViewProps) {
  const {
    items,
    groupedItemsFetched,
    groupedItemsLoading,
    changeCurrencyLoading,
    thirdPersonView,
    self
  } = useInvoiceItemsViewContext();

  const isEmptyItems = isEmpty(items);

  return (
    <div className="align-middle w-full relative">
      <CheckPermissions
        action={
          thirdPersonView
            ? self
              ? TeamsPermissions.READ_SELF_PAYMENTS_INVOICE_ITEMS_TABLE_HEADER
              : TeamsPermissions.READ_OTHER_PAYMENTS_INVOICE_ITEMS_TABLE_HEADER
            : InvoicesPermissions.READ_PAYMENTS_INVOICE_ITEMS_TABLE_HEADER
        }
      >
        {!(isEmptyItems && !groupedItemsLoading) && !readonly && (
          <InvoiceItemsViewHeader />
        )}
      </CheckPermissions>

      <LoadingSkeleton loaded={groupedItemsFetched && !changeCurrencyLoading}>
        <InvoiceItemsViewTable
          i18nInvoiceTotal={i18nInvoiceTotal}
          withoutPayments={withoutPayments}
          withPrepayment={withPrepayment}
          readonly={readonly}
        />
      </LoadingSkeleton>
    </div>
  );
}

export function InvoiceItemsViewWithContext({
  invoice,
  invoiceNanoId,
  thirdPersonView,
  self,
  cacheKeys,
  i18nInvoiceTotal,
  withoutPayments,
  withPrepayment,
  readonly,
  customFieldsCorrectStatus
}: InvoiceItemsViewWithContextProps) {
  return (
    <InvoiceItemsViewProvider
      invoice={invoice}
      invoiceNanoId={invoiceNanoId}
      thirdPersonView={thirdPersonView}
      self={self}
      cacheKeys={cacheKeys}
      customFieldsCorrectStatus={customFieldsCorrectStatus}
    >
      <InvoiceItemsView
        i18nInvoiceTotal={i18nInvoiceTotal}
        withoutPayments={withoutPayments}
        withPrepayment={withPrepayment}
        readonly={readonly}
      />
    </InvoiceItemsViewProvider>
  );
}

export default InvoiceItemsView;
