import React, { useCallback, useEffect, useMemo } from 'react';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import find from 'lodash/find';

import { CustomFieldsSelectFieldProps } from './CustomFieldsMultiSelectField.types';

import {
  FETCH_CUSTOM_FIELDS_QUERY,
  FetchCustomFieldsQueryResponse
} from '../../queries/fetchCustomFields.query';

import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';
import { usePaginatedCustomFields } from '../../hooks/usePaginatedCustomFields';

import { CustomFieldsSelectOptionType } from '../CustomFieldsSelectField';

import {
  MultiSelectField,
  MultiSelectFieldChangeCallbackType
} from '../../../../helpers/FormFields/MultiSelectField';

import { CustomFieldCache } from '../../CustomFieldCache';

function CustomFieldsMultiSelectField<FormDataType>({
  autoFocus,
  classNamePrefix,
  control,
  error,
  disabled,
  i18nLabel,
  i18nPlaceholder,
  inputWrapperClassName,
  name,
  onBlur,
  onChange,
  onMenuClose,
  onMenuOpen,
  openMenuOnFocus,
  multi,
  companyNanoId,
  companyId,
  customFieldsLocation
}: CustomFieldsSelectFieldProps<FormDataType>) {
  const {
    customFields,
    customFieldsLoading,
    customFieldsError,
    filterCustomFields
  } = usePaginatedCustomFields<FetchCustomFieldsQueryResponse>({
    cacheKey: CustomFieldCache.companyLocationCustomFields(
      companyNanoId,
      customFieldsLocation
    ),
    query: FETCH_CUSTOM_FIELDS_QUERY,
    initialFilters: {
      companyNanoId: companyNanoId ? { eq: companyNanoId } : undefined,
      companyId: companyId ? { eq: companyId } : undefined,
      location: { eq: customFieldsLocation }
    }
  });

  const prevCompanyNanoId = usePreviousValue(companyNanoId);
  const prevCompanyId = usePreviousValue(companyId);
  const prevCustomFieldsLocation = usePreviousValue(customFieldsLocation);

  useEffect(() => {
    if (
      (prevCompanyNanoId !== companyNanoId && companyNanoId) ||
      (prevCompanyId !== companyId && companyId) ||
      (prevCustomFieldsLocation !== customFieldsLocation &&
        customFieldsLocation)
    ) {
      filterCustomFields({
        companyNanoId: companyNanoId ? { eq: companyNanoId } : undefined,
        companyId: companyId ? { eq: companyId } : undefined,
        location: { eq: customFieldsLocation }
      });
    }
  }, [
    companyId,
    companyNanoId,
    customFieldsLocation,
    filterCustomFields,
    prevCompanyId,
    prevCompanyNanoId,
    prevCustomFieldsLocation
  ]);

  const options = useMemo<CustomFieldsSelectOptionType[]>(
    () =>
      map(customFields, (field) => ({
        value: field.id,
        label: field.label,
        field: field
      })),
    [customFields]
  );

  const handleOnChange = useCallback<MultiSelectFieldChangeCallbackType>(
    (newValue) => {
      onChange?.(
        isArray(newValue)
          ? map(newValue, (value) =>
              find(customFields, (field) => field.id === value.value)
            )
          : find(customFields, (field) => field.id === newValue.value)
      );
    },
    [customFields, onChange]
  );

  return (
    <MultiSelectField<FormDataType>
      autoFocus={autoFocus}
      classNamePrefix={
        classNamePrefix || (customFieldsError ? 'av_error' : 'av')
      }
      control={control}
      data={options}
      disabled={disabled || customFieldsLoading}
      emptyValue={undefined}
      error={error || customFieldsError}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      isSearchable
      labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
      menuPosition="fixed"
      name={name}
      onBlur={onBlur}
      onChange={handleOnChange}
      multi={multi}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      openMenuOnFocus={openMenuOnFocus}
      optionsLoading={customFieldsLoading}
    />
  );
}

export default CustomFieldsMultiSelectField;
