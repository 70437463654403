import { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName } from '../../../../../types';
import {
  InvoiceCustomFieldID,
  DeleteInvoiceCustomFieldCacheKeys
} from '../../../invoiceCustomFieldsTypes';

import { DELETE_INVOICE_CUSTOM_FIELD_QUERY } from '../../../queries/deleteInvoiceCustomField.query';

import { useDeleteInvoiceCustomField } from '../../../hooks/useDeleteInvoiceCustomField';

import { ConfirmModalButton } from '../../../../../helpers/buttons/ConfirmModalButton';

import {
  customFieldsKeys,
  stringsKeys,
  words
} from '../../../../../locales/keys';

interface DeleteInvoiceCustomFieldModalButtonProps {
  className?: ClassName;
  invoiceCustomFieldId: InvoiceCustomFieldID;
  cacheKeys?: DeleteInvoiceCustomFieldCacheKeys;
}

function DeleteInvoiceCustomFieldModalButton({
  className,
  invoiceCustomFieldId,
  cacheKeys
}: DeleteInvoiceCustomFieldModalButtonProps) {
  const {
    deleteInvoiceCustomField,
    deleteInvoiceCustomFieldErrorMessage,
    deleteInvoiceCustomFieldLoading,
    deleteInvoiceCustomFieldReset
  } = useDeleteInvoiceCustomField({
    cacheKeys,
    query: DELETE_INVOICE_CUSTOM_FIELD_QUERY
  });

  const handleDeleteInvoiceCustomField = useCallback(
    async () =>
      deleteInvoiceCustomField({
        uuid: invoiceCustomFieldId
      }),
    [invoiceCustomFieldId, deleteInvoiceCustomField]
  );

  return (
    <ConfirmModalButton
      className={
        className ||
        'py-0.5 pl-0.5 pr-0.5 rounded inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 hover:text-gray-700'
      }
      icon={IconsEnum.TRASH_SOLID}
      i18nSubmitText={words.delete}
      i18nText={stringsKeys.areYouSureYouWantToDeletTheField}
      i18nTitle={customFieldsKeys.deleteField}
      iconClassName="h-4 w-4"
      tooltipI18nText={words.delete}
      i18nButtonText={words.delete}
      errorMessage={deleteInvoiceCustomFieldErrorMessage}
      isLoading={deleteInvoiceCustomFieldLoading}
      onClose={deleteInvoiceCustomFieldReset}
      onSubmit={handleDeleteInvoiceCustomField}
    />
  );
}

export default DeleteInvoiceCustomFieldModalButton;
